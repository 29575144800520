import { LinearProgress } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';

export function LinearProgressContractInfo({ value, limit }) {
    const progressValue = Math.min((value / limit) * 100, 100);

    return (
        <LinearProgress
            sx={{
                width: '100%',
                maxWidth: 220,
                bgcolor: 'grey.300',
                height: 8,
                borderRadius: 3,
                '& .MuiLinearProgress-bar': {
                    bgcolor: value >= limit ? red[500] : value > (limit * 3 / 4) ? yellow[700] : green[500],
                },
            }}
            variant="determinate"
            value={progressValue}
        />
    );
}
