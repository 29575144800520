import { useState, useEffect } from 'react';
import axios from 'axios'
import { useForm, Controller } from "react-hook-form";
import {
    Box,
    Grid,
    MenuItem,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Typography,
} from '@mui/material';
import { estadosBrasileiros } from '../../../../configs/constants';
import {
    maskCEP,
    validateCEP,
    maskCNPJ,
    validateCNPJ,
    unmaskValue,
    getFormErrorMessage,
    fillInputErrors,
    getAddressByCEP
} from '../../../../configs/functions'
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { listaEmpresasMatriz, listaTiposEmpresas } from './configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { getContrato } from '../contract/functions';
import { CompanyLogoUpload } from './CompanyLogoUpload';
import { stateToUTC } from './configs/utils';
import { emailRegex } from '../../../../utils/regex';

export function CompanyForm({ open, setOpen, company, actionsAllowed, companyType = null, matrizParentFilial }) {
    const { dadosUsuario } = useAppContext();
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const isEditAction = !!company;
    const allowInteraction = (!isEditAction || (isEditAction && actionsAllowed));

    //checa se o state está montado
    const [formMounted, setFormMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [contratos, setContratos] = useState([]);

    // para carregar as matrizes
    const [matrizes, setMatrizes] = useState([]);
    const [idMatriz, setIdMatriz] = useState(matrizes.length > 0 ? matrizes[0].id : '');

    //para tipo empresas
    const [tiposEmpresas, setTiposEmpresas] = useState([]);
    const [idTipoEmpresa, setIdTipoEmpresa] = useState(tiposEmpresas.length > 0 ? tiposEmpresas[0].id : '');

    const [utc, setUtc] = useState('-3');

    const utcValues = [
        { value: '-2', label: 'UTC -2' },
        { value: '-3', label: 'UTC -3' },
        { value: '-4', label: 'UTC -4' },
        { value: '-5', label: 'UTC -5' },
    ];

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    async function fillAddressInputs(cep) {
        if (!cep || cep.length < 8) {
            setValue('logradouro', '', { shouldDirty: true });
            setValue('bairro', '', { shouldDirty: true });
            setValue('cidade', '', { shouldDirty: true });
            setValue('estado', '', { shouldDirty: true });
            setValue('UTC', '-3', { shouldDirty: true });
            setUtc('-3');
            return;
        }

        try {
            exibirDialog('Buscando endereço. Aguarde...');
            const apiCepResponse = await getAddressByCEP(cep);

            if (apiCepResponse) {
                // handle cep not found
                const getAddressResult = apiCepResponse.data;
                if (!getAddressResult?.erro) {
                    setValue('logradouro', getAddressResult.logradouro, { shouldDirty: true });
                    setValue('bairro', getAddressResult.bairro, { shouldDirty: true });
                    setValue('cidade', getAddressResult.localidade, { shouldDirty: true });
                    setValue('estado', getAddressResult.uf, { shouldDirty: true });
                    setValue('pais', 'Brasil', { shouldDirty: true });

                    const utcOffset = stateToUTC[getAddressResult.uf] || '-3';
                    setValue('UTC', utcOffset, { shouldDirty: true });
                    setUtc(utcOffset);
                } else {
                    exibirAlerta('Ops', 'CEP não encontrado', 'warning');
                }

                clearErrors('logradouro');
                clearErrors('bairro');
                clearErrors('cidade');
                clearErrors('estado');
                clearErrors('pais');
            }
        } catch (error) {
            exibirAlerta('Erro', 'CEP inválido', 'error');
        } finally {
            handleCloseDialog();
        }
    }

    const onSubmit = (data) => {
        const textTipoEmpresa = companyType === 'filial' ? 'Filial' : 'Empresa';

        data.cnpj = unmaskValue(data.cnpj);
        data.cep = unmaskValue(data.cep);

        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        let formDataToSend = {};
        if (isEditAction) {
            // data.status = 'ativa';
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;
        } else {
            delete data.id;
            data.status = 'ativa';

            // atribuirContrato para empresa, em uma criação
            if (dadosUsuario?.isSempher) {
                data.id_contrato = data.id_contrato;
            } else {
                data.id_contrato = dadosUsuario?.id_contrato;
            }

            // se for filial, pegar o id da matriz
            if (idTipoEmpresa == 2) {
                data.id_matriz = idMatriz;
            } else {
                delete data.id_matriz;
            }

            formDataToSend = data;
        }

        axios({
            method: isEditAction ? 'patch' : 'post',
            url: isEditAction ? `/empresas/${company.id}` : '/empresas',
            data: formDataToSend
        })
            .then((res) => {
                const id_empresa = res.data.data?.id;
                let hasError = false;

                if (!isEditAction) {
                    // atribuirAdmin da empresa para o usuário logado, em uma criação
                    if (dadosUsuario?.id && id_empresa) {
                        axios.post(`/empresas/atribuirAdmin`, { id_empresa, id_usuario: dadosUsuario.id })
                            .then((res) => { })
                            .catch((error) => {
                                hasError = true;
                            })
                    }
                }

                if (!hasError) {
                    setOpen(false);
                    const message = isEditAction ? `${textTipoEmpresa} atualizada com sucesso` : `${textTipoEmpresa} cadastrada com sucesso`;
                    exibirAlerta('Sucesso', message, 'success')
                } else {
                    exibirAlerta('Erro ao executar tarefa', 'Erro ao atribuir Admin para a empresa', 'error')
                }
            })
            .catch((error) => {
                let errorMessage = "";
                if (error?.response?.status === 403) {
                    errorMessage = error.response.data.error || 'Você não tem permissão para executar esta ação.';
                }
                else if (error?.response) {
                    if (error.response.data?.error) {
                        errorMessage = error.response.data?.error
                    } else {
                        errorMessage = error.response.data.message;
                    }
                    fillInputErrors(error, setError);
                } else {
                    errorMessage = `Erro ao cadastrar ${textTipoEmpresa}!`;
                }
                exibirAlerta('Erro', `${errorMessage}`, 'error')
            }).finally(() => {
                setIsLoading(false);
                handleCloseDialog();
            });
    };

    useEffect(() => {
        setFormMounted(true);

        return () => {
            setFormMounted(false);
        };
    }, []);

    useEffect(() => {
        if (formMounted) {
            if (!isEditAction) {
                setIdTipoEmpresa(company ? company.idTipo : (matrizParentFilial ? 2 : 1));

                if (idTipoEmpresa == 2 || companyType === 'filial') {
                    if (matrizParentFilial) {
                        setIdMatriz(matrizParentFilial.id);
                    } else {
                        setIdMatriz(matrizes.length > 0 ? matrizes[0].id : '')
                    }
                }
            }
            else {
                setIdTipoEmpresa(company.idTipo);
                if (company.idTipo == 2) {
                    setIdMatriz(company.idMatriz);
                }
            }

            //busca os tipos de empresas
            listaTiposEmpresas().then((res) => setTiposEmpresas(res.data.data));

            // buscar matrizes ativas
            getActiveMatrizes();

            if (dadosUsuario?.isSempher) {
                // para cadastro, buscar apenas contratos relacionáveis (disponíveis para vinculo)
                const filter = !isEditAction ? { "relacionaveis": true } : null;
                getContrato(filter)
                    .then(res => setContratos(res.data.data))
                    .catch(err => exibirAlerta('Erro', 'Erro ao buscar contratos', 'error'));
            }
        }
    }, [formMounted, matrizParentFilial, company, companyType]);

    async function getActiveMatrizes() {
        const filter = { "status": "ativa" };
        const matrizes = await listaEmpresasMatriz(filter);
        setMatrizes(matrizes);
    }

    useEffect(() => {
        if (open) {
            const defaultValues = {
                id: company ? company.id : '',
                fantasy_name: company ? company.nomeFantasia : '',
                company_name: company ? company.nomeCompanhia : '',
                email_responsavel: company ? company.emailResponsavel : '',
                logo: company ? company.logo : '',
                cnpj: company ? maskCNPJ(company.cnpj) : '',
                inscricao_estadual: company ? company.inscricaoEstadual : '',
                logradouro: company ? company.logradouro : '',
                numero: company ? company.numero : '',
                bairro: company ? company.bairro : '',
                complemento: company ? company.complemento : '',
                cidade: company ? company.cidade : '',
                estado: company ? company.estado : 'MG',
                pais: company ? company.pais : 'Brasil',
                UTC: company ? company.UTC : '-3',
                status: company ? company.status : 'ativa',
                cep: company ? maskCEP(company.cep) : '',
                id_contrato: company ? company.idContrato : '',
                id_tipo: company ? company.idTipo : (matrizParentFilial ? 2 : 1),
                id_matriz: company ? company.idMatriz : (matrizParentFilial ? matrizParentFilial.id : '')
            }
            reset(defaultValues);
            setUtc(company ? company.UTC : '-3');
        }
        else {
            // limpa o formulário ao sair
            reset();
            setIdMatriz('');
            setIdTipoEmpresa('');
            setUtc('-3');
        }
    }, [open, reset]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {isEditAction ? 'Editar' : 'Cadastrar nova'} {companyType === 'filial' ? 'Filial' : 'Empresa'}
                </DialogTitle>

                <DialogContent>
                    {!isEditAction &&
                        <DialogContentText sx={{ mb: 2 }}>
                            Preencha corretamente os dados abaixo para cadastrar uma nova {companyType == 'filial' ? 'filial' : 'empresa'}.
                        </DialogContentText>
                    }

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                <Grid container item xs={12} md={9} spacing={2}>
                                    {dadosUsuario?.isSempher &&
                                        <Grid item xs={12}>
                                            <Grid item xs={12} md={12}>
                                                <Controller
                                                    name='id_contrato'
                                                    control={control}
                                                    rules={{ required: true }}
                                                    disabled={!allowInteraction}
                                                    render={({ field: { ref, onChange, value, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            select
                                                            fullWidth
                                                            defaultValue={value}
                                                            disabled={isEditAction}
                                                            sx={{ mt: 1 }}
                                                            label={isEditAction ? 'Contrato Atual' : 'Contratos (disponíveis para vinculo)'}
                                                            onChange={(e) => {
                                                                setValue('id_contrato', e.target.value, { shouldDirty: true })
                                                                if (e.target.value) {
                                                                    clearErrors('id_contrato');
                                                                }
                                                            }}
                                                            error={!!errors.id_contrato}
                                                            helperText={getFormErrorMessage(errors, 'id_contrato')}
                                                        >
                                                            {contratos && contratos.length > 0 ? (
                                                                contratos.map(option => (
                                                                    <MenuItem
                                                                        key={option.id}
                                                                        value={option.id}
                                                                        disabled={option.is_max_empresas === 1}
                                                                    >
                                                                        <strong>ID:&nbsp;</strong>{option?.id || 'N/A'} -
                                                                        <strong>&nbsp;Nome:&nbsp;</strong>{option?.nome || 'N/A'} -
                                                                        <strong>&nbsp;Inicio:&nbsp;</strong>{option.inicio_contrato} -
                                                                        <strong>&nbsp;Fim:&nbsp;</strong>{option.fim_contrato}
                                                                        {option.is_max_empresas === 1 && ' - Limite de Empresas Atingido'}
                                                                    </MenuItem>
                                                                ))
                                                            ) : (
                                                                <MenuItem disabled>Nenhum disponível</MenuItem>
                                                            )}
                                                        </TextField>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    }

                                    {tiposEmpresas &&
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                select
                                                fullWidth
                                                sx={{ mt: 1 }}
                                                label="Tipo da Empresa"
                                                name='id_tipo'
                                                disabled={
                                                    !allowInteraction
                                                    ||
                                                    // se for edição e a empresa tiver unidades, não pode alterar o tipo
                                                    (isEditAction && company.hasUnites)
                                                    ||
                                                    // se for adição de filial, não pode alterar o tipo
                                                    (!isEditAction && matrizParentFilial ? true : false)
                                                }
                                                value={idTipoEmpresa}
                                                error={!!errors.id_tipo}
                                                helperText={getFormErrorMessage(errors, 'id_tipo')}
                                                {...register("id_tipo", { required: true })}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setValue('id_tipo', value, { shouldDirty: true });
                                                    setIdTipoEmpresa(value);
                                                    if (value == 2) {
                                                        if (matrizParentFilial) {
                                                            setIdMatriz(matrizParentFilial.id);
                                                        } else {
                                                            setIdMatriz(matrizes.length > 0 ? matrizes[0].id : '')
                                                        }
                                                    }
                                                    clearErrors('id_tipo');
                                                }}
                                            >
                                                {tiposEmpresas.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.titulo}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    }

                                    {idTipoEmpresa == 2 &&
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                select
                                                fullWidth
                                                // se for adição de filial, não pode alterar o tipo
                                                disabled=
                                                {
                                                    !allowInteraction
                                                    ||
                                                    (!isEditAction && matrizParentFilial ? true : false)
                                                    ||
                                                    matrizes.length == 0
                                                }
                                                sx={{ mt: 1 }}
                                                label={matrizes.length > 0 ? 'Matrizes Ativas' : 'Não há matrizes ativas'}
                                                name='id_matriz'
                                                value={idMatriz}
                                                error={!!errors.id_matriz}
                                                helperText={getFormErrorMessage(errors, 'id_matriz')}
                                                {...register("id_matriz", { required: companyType === 'filial' })}
                                                onChange={(e) => {
                                                    setValue('id_matriz', e.target.value, { shouldDirty: true });
                                                    setIdMatriz(e.target.value);
                                                }}
                                            >
                                                {matrizes.length > 0 && matrizes.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nomeFantasia}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            sx={{ mt: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                            label='Nome Fantasia'
                                            placeholder='Digite o Nome Fantasia'
                                            name='fantasy_name'
                                            error={!!errors.fantasy_name}
                                            helperText={getFormErrorMessage(errors, 'fantasy_name')}
                                            {...register("fantasy_name", { required: true })}
                                            disabled={!allowInteraction}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            sx={{ mt: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                            label='Razão Social'
                                            placeholder='Digite a Razão Social'
                                            name='company_name'
                                            error={!!errors.company_name}
                                            helperText={getFormErrorMessage(errors, 'company_name')}
                                            {...register("company_name", { required: true })}
                                            disabled={!allowInteraction}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography p>Logo da Empresa</Typography>
                                    <CompanyLogoUpload
                                        getValues={getValues}
                                        setValue={setValue}
                                        actionsAllowed={allowInteraction}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='cnpj'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={!allowInteraction}
                                        render={({ field: { ref, onChange, ...field } }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                margin='dense'
                                                label='CNPJ'
                                                placeholder='Digite o CNPJ'
                                                error={!!errors.cnpj}
                                                onChange={(e) => setValue('cnpj', maskCNPJ(e.target.value), { shouldDirty: true })}
                                                onBlur={(e) => {
                                                    if (!e.target.value || validateCNPJ(e.target.value)) {
                                                        clearErrors("cnpj");
                                                    } else {
                                                        setError("cnpj", { type: "invalid", message: "CNPJ inválido" });
                                                    }
                                                }}
                                                helperText={getFormErrorMessage(errors, 'cnpj')}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        label='Inscrição Estadual'
                                        placeholder='Digite a Inscrição Estadual'
                                        name='inscricao_estadual'
                                        {...register("inscricao_estadual")}
                                        disabled={!allowInteraction}
                                    />
                                </Grid>

                                <Grid container item xs={12} mr={{ md: 2 }}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            type='email'
                                            margin='dense'
                                            label='Email do Responsável'
                                            placeholder='Digite o Email'
                                            name='email_responsavel'
                                            error={!!errors.email_responsavel}
                                            helperText={getFormErrorMessage(errors, 'email_responsavel')}
                                            fullWidth
                                            {...register("email_responsavel", {
                                                required: true,
                                                pattern: {
                                                    value: emailRegex,
                                                    message: 'Por favor, insira um email válido'
                                                }
                                            })}
                                            autoComplete='off'
                                            inputProps={{ form: { autocomplete: 'off' } }}
                                            disabled={!allowInteraction}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Controller
                                        name='cep'
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={!allowInteraction}
                                        render={({ field: { ref, onChange, ...field } }) => (
                                            <TextField
                                                {...field}
                                                label='CEP'
                                                placeholder='Digite o CEP'
                                                error={!!errors.cep}
                                                onChange={(e) => {
                                                    setValue('cep', maskCEP(e.target.value), { shouldDirty: true })
                                                    const unmaskedValue = unmaskValue(e.target.value);
                                                    fillAddressInputs(unmaskedValue);
                                                }}
                                                onBlur={(e) => {
                                                    if (!validateCEP(e.target.value)) {
                                                        setError("cep", { type: "invalid", message: "cep inválido" })
                                                    } else {
                                                        clearErrors("cep")
                                                    }
                                                }}
                                                helperText={getFormErrorMessage(errors, 'cep')}
                                                margin='dense'
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Controller
                                        name='UTC'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={utc}
                                        disabled={!allowInteraction}
                                        render={({ field: { ref, onChange, value, ...field } }) => (
                                            <TextField
                                                {...field}
                                                select
                                                fullWidth
                                                defaultValue={value}
                                                sx={{ mt: 1 }}
                                                value={utc}
                                                label='Fuso horário'
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setValue('UTC', newValue, { shouldDirty: true })
                                                    setUtc(newValue);
                                                    if (newValue) {
                                                        clearErrors('UTC');
                                                    }
                                                }}
                                                error={!!errors.UTC}
                                                helperText={getFormErrorMessage(errors, 'UTC')}
                                            >
                                                {utcValues.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={9}>
                                    <TextField
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        label='Endereço'
                                        placeholder='Digite o endereço'
                                        name='logradouro'
                                        error={!!errors.logradouro}
                                        helperText={getFormErrorMessage(errors, 'logradouro')}
                                        {...register("logradouro", { required: true })}
                                        disabled={!allowInteraction}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        label='Número'
                                        placeholder='Número'
                                        name='numero'
                                        error={!!errors.numero}
                                        helperText={getFormErrorMessage(errors, 'numero')}
                                        {...register("numero")}
                                        disabled={!allowInteraction}
                                    />
                                </Grid>

                                <Grid item xs={12} md={7}>
                                    <TextField
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        label='Bairro'
                                        placeholder='Digite o Bairro'
                                        name='bairro'
                                        error={!!errors.bairro}
                                        helperText={getFormErrorMessage(errors, 'bairro')}
                                        {...register("bairro", { required: true })}
                                        disabled={!allowInteraction}
                                    />
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <TextField
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        label='Complemento'
                                        placeholder='Digite o Complemento'
                                        name='complemento'
                                        {...register("complemento")}
                                        disabled={!allowInteraction}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        label='Cidade'
                                        placeholder='Digite a Cidade'
                                        name='cidade'
                                        error={!!errors.cidade}
                                        helperText={getFormErrorMessage(errors, 'cidade')}
                                        {...register("cidade", { required: true })}
                                        disabled={!allowInteraction}
                                    />
                                </Grid>

                                {estadosBrasileiros &&
                                    <Grid item xs={12} md={4}>
                                        <Controller
                                            name='estado'
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={company ? company.estado : "MG"}
                                            disabled={!allowInteraction}
                                            render={({ field: { ref, onChange, ...field } }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    fullWidth
                                                    sx={{ mt: 1 }}
                                                    label="Estado"
                                                    onChange={(e) => {
                                                        setValue('estado', e.target.value, { shouldDirty: true });
                                                        clearErrors('estado');
                                                    }}
                                                    error={!!errors.estado}
                                                    helperText={getFormErrorMessage(errors, 'estado')}
                                                >
                                                    {estadosBrasileiros.map((option) => (
                                                        <MenuItem key={option.sigla} value={option.sigla}>
                                                            {option.nome}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        label='País'
                                        placeholder='Digite o País'
                                        name='pais'
                                        error={!!errors.pais}
                                        helperText={getFormErrorMessage(errors, 'pais')}
                                        {...register("pais", { required: true })}
                                        disabled={!allowInteraction}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm
                    errors={errors}
                    isLoading={isLoading}
                    setOpen={setOpen}
                    disableSubmit={!allowInteraction}
                />
            </form>
        </Dialog>
    );
}