import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Grid,
    Breadcrumbs,
    IconButton,
    Collapse,
    Box,
    Typography,
    Button,
    Switch,
    Tooltip,
    Stack
} from '@mui/material';
import {
    Add,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Visibility
} from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import { formatarCNPJ, hasPermission, checkPermissionsAndRedirect } from '../../../../configs/functions';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { CompanyForm } from './CompanyForm';
import ConfirmDialog from '../../../common/ConfirmDialog';
import CompanyUnits from './CompanyUnits';
import { EditIcon, OfficeIcon, TrashIcon } from '../../../common/Icons';
import { useAppContext } from '../../../../contexts/AppContext';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { checkMaxEmpresas, listaEmpresas, listaFiliais } from './configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { useModuleContext } from '../../../../contexts/ModuleProvider';

const Row = ({ matriz, actionsAllowed, handleExibirAlerta, handleOpenEditForm, isLimitReached, handleOpenModalFilial, handleUpdateCompanyStatus }) => {
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [active, setActive] = useState(matriz.status === 'ativa' ? true : false);
    const [filiais, setFiliais] = useState([]);
    const [loaded, setLoaded] = useState(false)

    const deleteEmpresa = (id) => {
        axios.delete(`empresas/${id}`).then((response) => {
            const message = response.data.message ?? 'Matriz inativada com sucesso'
            handleExibirAlerta('Sucesso', message, 'success')
            handleUpdateCompanyStatus(matriz.id, 'inativa')
            setActive(false)
        }).catch(() => {
            handleExibirAlerta('Erro', 'Erro ao executar operação', 'error')
        })

        setOpenDialog(false)
    }

    const handleToggleCompanyStatus = () => {
        if (!hasPermission(["admin", "admin_empresas", "update_empresas"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        const novoStatus = (matriz.status === 'ativa') ? 'inativa' : 'ativa'

        axios.patch(`empresas/${matriz.id}`, { status: novoStatus })
            .then((response) => {
                const message = active ? 'Matriz inativada com sucesso' : 'Matriz ativada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateCompanyStatus(matriz.id, novoStatus)
            }).catch(() => {
                handleExibirAlerta('Erro', 'Erro ao executar operação', 'error')
            })
    }

    const carregaFiliais = () => {
        listaFiliais(matriz.id).then((res) => {
            setFiliais(res.data)
        }).catch(() => {
            handleExibirAlerta('Erro', 'Erro ao carregar filiais', 'error')
        }).finally(() => {
            setLoaded(true)
        });
    }

    useEffect(() => {
        if (matriz.hasUnites) {
            carregaFiliais();
        }
    }, [open]);

    return (
        <>
            <TableRow hover key={matriz.cnpj}>
                <TableCell align='center'>
                    {(matriz.hasUnites && filiais.length > 0) && <IconButton
                        aria-label="Detalhes"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>}
                </TableCell>
                <TableCell>{formatarCNPJ(matriz.cnpj)}</TableCell>
                <TableCell>{matriz.nomeFantasia}</TableCell>
                <TableCell>{`${matriz.cidade} - ${matriz.estado}`}</TableCell>
                <TableCell>
                    <Switch
                        checked={active}
                        onChange={handleToggleCompanyStatus}
                        disabled={!hasPermission(["admin", "admin_empresas", "update_empresas"]) || !actionsAllowed}
                    />
                </TableCell>
                <TableCell>
                    <Stack direction="row" justifyContent='center'>
                        {hasPermission(["admin", "admin_empresas", "create_empresas"]) && actionsAllowed &&
                            <Tooltip title="Adicionar Filial">
                                <IconButton
                                    aria-label="Adicionar"
                                    color="primary"
                                    onClick={() => handleOpenModalFilial(null)}
                                    disabled={!active || isLimitReached}
                                >
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        }

                        {hasPermission(["admin", "admin_empresas", "delete_empresas"]) &&
                            <Tooltip title={actionsAllowed ? "Editar Matriz" : "Visualizar Matriz"}>
                                <IconButton
                                    aria-label="Editar"
                                    onClick={handleOpenEditForm}
                                >
                                    {actionsAllowed ? <EditIcon /> : <Visibility color='primary' />}
                                </IconButton>
                            </Tooltip>
                        }

                        {hasPermission(["admin", "admin_empresas", "delete_empresas"]) && actionsAllowed &&
                            <Tooltip title="Excluir Matriz">
                                <IconButton
                                    aria-label="Excluir"
                                    onClick={() => setOpenDialog(true)}
                                >
                                    <TrashIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Stack>
                </TableCell>
            </TableRow>

            <TableRow key={matriz.id}>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <CompanyUnits
                                filiais={filiais}
                                loaded={loaded}
                                handleCarregaFiliais={carregaFiliais}
                                handleOpenModalFilial={handleOpenModalFilial}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir esta empresa?'}
                title={'Excluir Empresa'}
                goAction={() => deleteEmpresa(matriz.id)}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    );
};

function Companies() {
    const { dadosUsuario } = useAppContext();
    const { exibirAlerta } = useCommonItems();
    const { verifyIntegrationMode } = useModuleContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [matrizes, setMatrizes] = useState([]);
    const [open, setFormOpen] = useState(false);
    const [companyType, setCompanyType] = useState('matriz');
    const [matrizParentFilial, setMatrizParentFilial] = useState(null);
    const [isMaxEmpresas, setIsMaxEmpresas] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    //controla o idEmpresa para casos de abrir o form de edicao
    const [company, setCompany] = useState(null);

    async function carregaMatrizes() {
        const timer = setTimeout(() => setIsLoading(true), 500);
        try {
            const res = await listaEmpresas(null, page, rowsPerPage);
            const onlyMatrizes = res.data.data.filter((empresa) => empresa.tipo.tituloTipo === 'Matriz');
            setMatrizes(onlyMatrizes);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar empresas', 'error')
        } finally {
            clearTimeout(timer);
            setIsLoading(false);
        }
    }

    const updateCompanyStatus = (companyId, status) => setMatrizes(
        matrizes.map((matriz) => {
            if (matriz.id === companyId) {
                matriz.status = status;
            }
            return matriz;
        })
    );

    useEffect(() => {
        if (!open) {
            carregaMatrizes();
            setCompany(null);
            setMatrizParentFilial(null);
            setCompanyType('matriz');
        }
    }, [open, page, rowsPerPage]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_empresas", "list_empresas"];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    useEffect(() => {
        const fetchContrato = async () => {
            try {
                // verificando se qtd de empresas atingiu o limite do contrato
                const limitReached = await checkMaxEmpresas(numTotalItems);
                setIsMaxEmpresas(limitReached);
            } catch (error) {
                exibirAlerta('Erro', 'Erro ao verificar limite de empresas:', 'error');
                console.error('Erro ao verificar limite de empresas:', error);
            }
        };
        fetchContrato();
    }, [numTotalItems]);

    return (
        <>
            <Helmet title="Empresas" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="end"
                spacing={3}
            >
                <Grid item xs={12} md={9}>
                    <Breadcrumbs>
                        <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento</Typography>
                        <Typography variant='span'>Empresas</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<OfficeIcon fontSize="large" />}
                        title='Empresas'
                        description=' Administre Empresas Matrizes/Filiais e seus detalhes'
                    />
                </Grid>

                {hasPermission(["admin", "admin_empresas", "create_empresas"]) &&
                    <Grid item xs={12} md={3}>
                        <Typography
                            sx={{
                                textAlign: {
                                    xs: 'center',
                                    md: 'right'
                                }
                            }}
                        >
                            <TooltipSelectDisabled isDisabled={!isMaxEmpresas || dadosUsuario?.isSempher} text="Limite máximo de empresas atingido">
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    onClick={() => setFormOpen(true)}
                                    disabled={isMaxEmpresas && !dadosUsuario?.isSempher}
                                >
                                    Cadastrar Empresa
                                </Button>
                            </TooltipSelectDisabled>
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="Empresas">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'></TableCell>
                                    <TableCell>CNPJ</TableCell>
                                    <TableCell>Nome Fantasia</TableCell>
                                    <TableCell>Cidade/Estado</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ?
                                    <LoadingTableRow />
                                    : matrizes && matrizes.length > 0 ?
                                        matrizes?.map((matriz) => (
                                            <Row
                                                key={matriz.id}
                                                matriz={matriz}
                                                isSempher={dadosUsuario?.isSempher ?? false}
                                                handleExibirAlerta={exibirAlerta}
                                                handleUpdateCompanyStatus={updateCompanyStatus}
                                                isLimitReached={isMaxEmpresas}
                                                handleOpenEditForm={() => {
                                                    setCompany(matriz);
                                                    setFormOpen(true);
                                                }}
                                                handleOpenModalFilial={(filial) => {
                                                    setCompanyType('filial');
                                                    setCompany(filial);
                                                    setMatrizParentFilial(matriz);
                                                    setFormOpen(true);
                                                }}
                                                actionsAllowed={verifyIntegrationMode("Empresa", matriz.id)}
                                            />
                                        ))
                                        : <EmptyTableRow />
                                }
                            </TableBody>
                        </Table>

                        <CustomTablePagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            <CompanyForm
                open={open}
                setOpen={setFormOpen}
                company={company}
                companyType={companyType}
                matrizParentFilial={matrizParentFilial}
                actionsAllowed={verifyIntegrationMode("Empresa", company?.id)}
            />
        </>
    );
}

export default Companies;