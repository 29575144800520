import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
} from '@mui/material';
import { Add, Visibility } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { BusinessReportIcon, EditIcon, TrashIcon } from '../../../common/Icons';
import { atualizaCentroCusto, deletaCentroCusto, listaCentroCustos } from './configs/functions';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { listaEmpresas } from '../companies/configs/functions';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { CostCenterForm } from './CostCenterForm';
import { useModuleContext } from '../../../../contexts/ModuleProvider';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable';

const CostCenterRow = ({ data, actionsAllowed, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status);
    const [openDialog, setOpenDialog] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_centroCustos", "update_centroCustos"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaCentroCusto(data.id, { status: !active })
            if (res) {
                const message = active ? 'Centro de custo inativado com sucesso' : 'Centro de custo ativado com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.id, !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const deleteCostCenter = () => {
        const id = data.id;
        deletaCentroCusto(id)
            .then((response) => {
                const message = response.data.message ?? 'Centro de custo deletado com sucesso'
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow key={data.id}>
                <TableCell>{data.nome}</TableCell>
                <TableCell>{data.codigo}</TableCell>
                <TableCell>{data.descricao}</TableCell>

                <TableCell>
                    <Switch checked={active}
                        onChange={handleToggleStatus}
                        disabled={!hasPermission(["admin", "admin_centroCustos", "update_centroCustos"]) || !actionsAllowed}
                    />
                </TableCell>

                <TableCell align='right' sx={{ minWidth: 110 }}>
                    {hasPermission(["admin", "admin_centroCustos", "update_centroCustos"]) &&
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            {actionsAllowed ? <EditIcon /> : <Visibility color='primary' />}
                        </IconButton>
                    }

                    {hasPermission(["admin", "admin_centroCustos", "delete_centroCustos"]) && actionsAllowed &&
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir este Centro de custo?'}
                title={`Excluir Centro de custo ${data.nome}`}
                goAction={deleteCostCenter}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

export function ListOfCostCenter() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();
    const { verifyIntegrationMode, getEmpresasFilteredByModule } = useModuleContext();

    const [moduleActionsAllowed, setModuleActionsAllowed] = useState(null);

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [centroCustos, setCentroCustos] = useState(null);
    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCostCenter, setSelectedCostCenter] = useState(null);

    const columns = useMemo(() => [
        { field: 'nome', headerName: 'Centro de Custo' },
        { field: 'codigo', headerName: 'Código' },
        { field: 'descricao', headerName: 'Descrição' },
        { field: 'empresa.nomeFantasia', headerName: 'Empresa', valueGetter: (params) => params.row.empresa?.nomeFantasia },
        { field: 'status', headerName: 'Status', valueGetter: (params) => params.row.status === 1 ? 'Ativo' : 'Inativo' },
    ], []);

    const carregaCentrosCustos = async () => {
        setIsLoading(true);
        try {
            const filterAllPaginated = {
                id_empresas: Array.isArray(selectedEmpresaId) ? selectedEmpresaId : [selectedEmpresaId],
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };
            const res = await listaCentroCustos(filterAllPaginated);
            setCentroCustos(res.data.data);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            console.error('Erro ao carregar Centros de custo:', error);
            exibirAlerta('Ops', 'Erro ao carregar Centros de Custo, tente novamente mais tarde.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    function handleEditCostCenter(item) {
        setSelectedCostCenter(item);
        setOpenForm(true);
    }

    const updateCostCenterStatus = (categoryId, status) => setCentroCustos(
        centroCustos.map((item) => {
            if (item.id === categoryId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            if (selectedEmpresaId) {
                carregaCentrosCustos();
            }
            setSelectedCostCenter(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEmpresaId, openForm, page, rowsPerPage]);

    useEffect(() => {
        if (selectedEmpresaId) {
            const empresaHasActionsAllowed = verifyIntegrationMode('CentroCusto', selectedEmpresaId);
            setModuleActionsAllowed(empresaHasActionsAllowed);
        }
    }, [selectedEmpresaId, verifyIntegrationMode]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_centroCustos", "list_centroCustos"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        const selectedEmpresaIdSession = getEmpresaIdSession();
        if (selectedEmpresaIdSession) {
            setSelectedEmpresaId(selectedEmpresaIdSession);
        }

        listaEmpresas()
            .then((response => {
                const empresasData = response?.data.data;
                setEmpresas(empresasData);

                if (!selectedEmpresaIdSession) {
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }
            }));
    }, [getEmpresaIdSession, setEmpresaIdSession]);

    return (<>
        <Helmet title="Centros de Custo" />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to="/">
                        <HomeIcon fontSize="small" />
                    </LinkNavegacao>
                    <Typography variant='span'>Gerenciamento</Typography>
                    <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
                    <Typography variant='span'>Centros de Custo</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<BusinessReportIcon fontSize='large' />}
                    title="Centros de Custo"
                    description='Administre os Centros de Custo da sua(s) empresa(s).'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <SelectEmpresaFilter
                        empresas={empresas}
                        id_empresas={empresas}
                        defaultValue={selectedEmpresaId}
                        onChangeValue={(value) => {
                            setSelectedEmpresaId(value);
                            setEmpresaIdSession(value);
                            setPage(0);
                        }}
                    />

                    {hasPermission(["admin", "admin_centroCustos", "create_centroCustos"]) &&
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar Centro de Custo
                            </Button>
                        </Typography>
                    }
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <CustomToolbarExportTable
                        rows={centroCustos}
                        columns={columns}
                        reportName="relatorio_centro_custo"
                        reportPdfTitle="RELATÓRIO DE CENTROS DE CUSTO"
                        getDataWithoutPaginate={() => listaCentroCustos({ id_empresas: [selectedEmpresaId] })}
                        getSelectedEmpresa={() => empresas.find(empresa => empresa.id === Number(selectedEmpresaId))}
                    />

                    <Table aria-label="Centros de Custo">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Código</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : centroCustos && centroCustos?.length > 0
                                    ? centroCustos.map((item) =>
                                        <CostCenterRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditCostCenter}
                                            handleUpdateStatus={updateCostCenterStatus}
                                            updateData={carregaCentrosCustos}
                                            actionsAllowed={moduleActionsAllowed}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <CostCenterForm
            open={openForm}
            setOpen={setOpenForm}
            selectedCostCenter={selectedCostCenter}
            empresas={getEmpresasFilteredByModule('CentroCusto', empresas, !selectedCostCenter)}
            actionsAllowed={moduleActionsAllowed}
        />
    </>);
}