import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
  Stack,
} from '@mui/material';
import { ContentCopy, CheckCircleOutlineOutlined, Key } from '@mui/icons-material';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';

export function GenerateIntegrationTokenDialog({ open, selectedCompany, handleClose }) {
  const { exibirAlerta } = useCommonItems();
  const [copiedKeys, setCopiedKeys] = useState({ public: false, secret: false });

  const copyTokenToClipboard = (token, tokenType) => {
    try {
      navigator.clipboard.writeText(token);
      exibirAlerta("Sucesso", "Chave copiada para a área de transferência", "success");
      setCopiedKeys((prevState) => ({ ...prevState, [tokenType]: true }));
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  useEffect(() => {
    if (!open) {
      setCopiedKeys({ public: false, secret: false });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='md'
      disableEscapeKeyDown
    >
      <DialogTitle>
        Chaves Geradas com Sucesso!
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText pb={3}>
          <Typography variant="body1" gutterBottom>
            Estas chaves são de <strong>uso único</strong>. Certifique-se de copiá-las antes de fechar esta janela.
          </Typography>

          <Typography variant="body1" gutterBottom>
            Chaves geradas para a empresa: <strong>{selectedCompany?.nomeFantasia}</strong>.
          </Typography>
        </DialogContentText>

        <Stack direction="column" width={'100%'} justifyContent="center" alignItems="center" spacing={2}>
          <TextField
            fullWidth
            label="Public Key"
            defaultValue={selectedCompany?.public_key}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton><Key color='info' /></IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip arrow title="Copiar Chave">
                    <IconButton onClick={() => copyTokenToClipboard(selectedCompany?.public_key, 'public')}>
                      {!copiedKeys.public ? <ContentCopy color='info' /> : <CheckCircleOutlineOutlined color='success' />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Secret Key"
            defaultValue={selectedCompany?.secret_key}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton><Key color='info' /></IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip arrow title="Copiar Chave">
                    <IconButton onClick={() => copyTokenToClipboard(selectedCompany?.secret_key, 'secret')}>
                      {!copiedKeys.secret ? <ContentCopy color='info' /> : <CheckCircleOutlineOutlined color='success' />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} disabled={!copiedKeys.public || !copiedKeys.secret}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
