import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Collapse,
  Card,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { useAppContext } from '../../../../../contexts/AppContext';
import { TableFilterButtons } from '../../../../common/TableFilterButtons';

const tiposMaquina = [
  { id: 'maquina', nome: 'Máquina' },
  { id: 'balcao', nome: 'Balcão' },
];

export function EquipmentsTableFilter({ isOpenFilters, isLoadingList, empresas, pontosDeEntrega }) {
  const { setEmpresaIdSession } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams()

  const nome = searchParams.get('nome');
  const id_empresas = searchParams.get('id_empresas');
  const id_ponto_entrega = searchParams.get('id_ponto_entrega');
  const tipo = searchParams.get('tipo');
  const status = searchParams.get('status');

  const perPage = searchParams.get('perPage');

  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    reset({
      nome: nome ?? '',
      id_empresas: id_empresas ?? '',
      id_ponto_entrega: id_ponto_entrega ?? '',
      tipo: tipo ?? '',
      status: status ?? '',
    });
  }, [id_empresas, id_ponto_entrega, nome, reset, status, tipo])

  function handleSubmitFilter(data) {
    for (const key in data) {
      // remove campos vazios
      if (data[key] === '') {
        delete data[key];
      }
    }

    const queryFilters = {
      ...data,
      page: '1',
      perPage: perPage ?? '10'
    }

    // fill url params with filters
    setSearchParams(queryFilters);

    if (data.id_empresas) {
      setEmpresaIdSession(data.id_empresas);
    }
  }

  return (
    <Grid item xs={12} component="form" onSubmit={handleSubmit(handleSubmitFilter)}>
      <Collapse in={isOpenFilters}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="nome"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Nome do Equipamento"
                    fullWidth
                    size="small"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="id_empresas"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Empresa"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {empresas.length > 0 && empresas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nomeFantasia}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="id_ponto_entrega"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Ponto de Entrega"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {pontosDeEntrega.length > 0 && pontosDeEntrega.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="tipo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Tipo de Equipamento"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {tiposMaquina.length > 0 && tiposMaquina.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Status"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    <MenuItem value="1">Ativo</MenuItem>
                    <MenuItem value="0">Inativo</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <TableFilterButtons
              isLoading={isLoadingList}
              handleClearFilters={() => {
                setSearchParams((state) => {
                  state.set('page', '1')
                  state.delete('nome')
                  state.delete('id_empresas')
                  state.delete('id_ponto_entrega')
                  state.delete('tipo')
                  state.delete('status')
                  return state
                });

                reset({
                  nome: '',
                  id_empresas: '',
                  id_ponto_entrega: '',
                  tipo: '',
                  status: '',
                });
              }}
            />
          </Grid>
        </Card>
      </Collapse>
    </Grid>
  );
}
