import { TableRow, TableCell, Skeleton, Stack } from '@mui/material';

export function IntegrationSkeletonTableRow({ numRows = 5 }) {
  return (
    <>
      {Array(numRows).fill(0).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" width={"100%"} />
            <Skeleton variant="text" width={"120%"} />
          </TableCell>

          <TableCell align='center'>
            <Stack direction="row" spacing={1.2} justifyContent={'center'} sx={{ p: 0.5 }}>
              <Skeleton variant="rounded" width={22} height={22} />
            </Stack>
          </TableCell>

          <TableCell align='center' sx={{ minWidth: 110 }}>
            <Stack direction="row" spacing={1.2} justifyContent={'center'} sx={{ p: 0.5 }}>
              <Skeleton variant="rounded" width={22} height={22} />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}