import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  Collapse,
  Card,
  Grid,
  TextField,
} from '@mui/material';
import { TableFilterButtons } from '../../../common/TableFilterButtons';

export function AbilitiesTableFilter({ isOpenFilters, isLoadingList, empresas }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const nome = searchParams.get('nome');
  const perPage = searchParams.get('perPage');

  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      nome: nome ?? '',
    },
  });

  function handleSubmitFilter(data) {
    for (const key in data) {
      // remove campos vazios
      if (data[key] === '') {
        delete data[key];
      }
    }

    const queryFilters = {
      ...data,
      page: '1',
      perPage: perPage ?? '10'
    }

    // fill url params with filters
    setSearchParams(queryFilters);
  }

  return (
    <Grid item xs={12} component="form" onSubmit={handleSubmit(handleSubmitFilter)}>
      <Collapse in={isOpenFilters}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="nome"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Nome da Permissão"
                    fullWidth
                    size="small"
                    {...field}
                  />
                )}
              />
            </Grid>

            <TableFilterButtons
              isLoading={isLoadingList}
              handleClearFilters={() => {
                setSearchParams((state) => {
                  state.delete('nome')
                  state.set('page', '1')

                  return state
                })

                reset({
                  nome: '',
                });
              }}
            />
          </Grid>
        </Card>
      </Collapse>
    </Grid>
  );
}
