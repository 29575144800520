import { useState } from 'react';
import get from 'lodash/get';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { CsvIcon, PdfIcon, Xls502Icon } from './Icons';
import { defaultExportDataFromTableToPDF, exportToCsvWithoutPaginate, exportToExcelWithoutPaginate } from '../screens/epis/reports/configs/utils';
import { useAppContext } from '../../contexts/AppContext';
import { useCommonItems } from '../../contexts/CommonItensProvider';

export const CustomToolbarExportTable = ({
  reportName,
  columns,
  getDataWithoutPaginate,
  getSelectedEmpresa,
  reportPdfTitle,
  dataPath = 'data.data',
  ...rest
}) => {
  const { dadosUsuario } = useAppContext();
  const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleExportExcel = async () => {
    try {
      exibirDialog('Buscando dados, aguarde...', 'info');
      const response = await getDataWithoutPaginate();
      const data = get(response, dataPath);

      if (!data) {
        throw new Error('Nenhum dado encontrado.');
      }

      exportToExcelWithoutPaginate(reportName, columns, data);
    } catch (error) {
      console.log(error);
      exibirAlerta('Erro', 'Ocorreu um erro ao buscar os dados.', 'error');
    } finally {
      handleCloseDialog();
      handleCloseMenu();
    }
  };

  const handleExportCsv = async () => {
    try {
      exibirDialog('Buscando dados, aguarde...', 'info');
      const response = await getDataWithoutPaginate();
      const data = get(response, dataPath);

      if (!data) {
        throw new Error('Nenhum dado encontrado.');
      }

      exportToCsvWithoutPaginate(reportName, columns, data);
    } catch (error) {
      console.log(error);
      exibirAlerta('Erro', 'Ocorreu um erro ao buscar os dados.', 'error');
    } finally {
      handleCloseDialog();
      handleCloseMenu();
    }
  };

  const handleExportPdf = async () => {
    try {
      exibirDialog('Exportando dados para PDF, aguarde...');
      const response = await getDataWithoutPaginate();
      const data = get(response, dataPath);

      if (!data) {
        throw new Error('Nenhum dado encontrado.');
      }

      const selectedEmpresa = getSelectedEmpresa();
      defaultExportDataFromTableToPDF({ tableData: data, columns, selectedEmpresa, reportTitle: reportPdfTitle, reportName, dadosUsuario });
    } catch (error) {
      console.error(error);
      exibirAlerta('Erro', 'Ocorreu um erro ao buscar os dados.', 'error');
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        pt: 1,
        px: 1,
        backgroundColor: '#d8d8d8',
      }}
    >
      <Box sx={{ flexGrow: 1 }} />

      <Button
        size="small"
        variant="text"
        startIcon={<FileDownloadOutlined />}
        onClick={handleOpenMenu}
      >
        Exportar
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleExportExcel}>
          <Xls502Icon sx={{ mr: 1 }} /> Exportar como Excel
        </MenuItem>

        <MenuItem onClick={handleExportCsv}>
          <CsvIcon sx={{ mr: 1 }} /> Exportar como CSV
        </MenuItem>

        <MenuItem onClick={handleExportPdf} disabled={reportPdfTitle === undefined}>
          <PdfIcon sx={{ mr: 1 }} /> Exportar como PDF
        </MenuItem>
      </Menu>
    </Box>
  );
};