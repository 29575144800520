import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
} from '@mui/material';
import { Add, Visibility } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { EditIcon, PuzzleIcon, TrashIcon } from '../../../common/Icons';
import { atualizaSetor, deletaSetor, listaSetores } from './configs/functions';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { listaEmpresas } from '../companies/configs/functions';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { SectorForm } from './SectorForm';
import { useModuleContext } from '../../../../contexts/ModuleProvider';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable';

const SectorRow = ({ data, actionsAllowed, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status[0]);
    const [openDialog, setOpenDialog] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_setores", "update_setores"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaSetor(data.setores_id[0], { status: !active })
            if (res) {
                const message = active ? 'Setor inativado com sucesso' : 'Setor ativado com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.setores_id[0], !data.status[0])
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const deleteSector = () => {
        const id = data.setores_id[0];
        deletaSetor(id)
            .then((response) => {
                const message = response.data.message ?? 'Setor excluído com sucesso'
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow key={data.setores_id[0]}>
                <TableCell>{data.titulo}</TableCell>
                <TableCell>{data.cod_externo}</TableCell>
                <TableCell>{data.descricao}</TableCell>

                <TableCell>
                    <Switch
                        checked={active}
                        onChange={handleToggleStatus}
                        disabled={!hasPermission(["admin", "admin_setores", "update_setores"]) || !actionsAllowed}
                    />
                </TableCell>

                <TableCell align='right' sx={{ minWidth: 110 }}>
                    {hasPermission(["admin", "admin_setores", "update_setores"]) &&
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            {actionsAllowed ? <EditIcon /> : <Visibility color='primary' />}
                        </IconButton>
                    }

                    {hasPermission(["admin", "admin_setores", "delete_setores"]) && actionsAllowed &&
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir este Setor?'}
                title={`Excluir Setor ${data.nome}`}
                goAction={deleteSector}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

function Sectors() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();
    const { verifyIntegrationMode, getEmpresasFilteredByModule } = useModuleContext();

    const [moduleActionsAllowed, setModuleActionsAllowed] = useState(null);

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [setores, setSetores] = useState(null);
    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedSector, setSelectedSector] = useState(null);

    const columns = useMemo(() => [
        { field: 'titulo', headerName: 'Setor' },
        { field: 'cod_externo', headerName: 'Cód. Externo' },
        { field: 'descricao', headerName: 'Descrição' },
        { field: 'status', headerName: 'Status', valueGetter: (params) => params.row.status[0] === 1 ? 'Ativo' : 'Inativo' },
    ], []);

    const carregaSetores = async () => {
        setIsLoading(true);
        try {
            const filterAllPaginated = {
                id_empresas: Array.isArray(selectedEmpresaId) ? selectedEmpresaId : [selectedEmpresaId],
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };
            const res = await listaSetores(filterAllPaginated);
            setSetores(res.data.data);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            console.error('Erro ao carregar Setores:', error);
            exibirAlerta('Ops', 'Erro ao carregar Setores, tente novamente mais tarde.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    function handleEditSector(item) {
        setSelectedSector(item);
        setOpenForm(true);
    }

    const updateSectorStatus = (categoryId, status) => setSetores(
        setores.map((item) => {
            if (item.id === categoryId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            if (selectedEmpresaId) {
                carregaSetores();
            }
            setSelectedSector(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEmpresaId, openForm, page, rowsPerPage]);

    useEffect(() => {
        if (selectedEmpresaId) {
            const empresaHasActionsAllowed = verifyIntegrationMode('Setor', selectedEmpresaId);
            setModuleActionsAllowed(empresaHasActionsAllowed);
        }
    }, [selectedEmpresaId, verifyIntegrationMode]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_setores", "list_setores"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        const selectedEmpresaIdSession = getEmpresaIdSession();
        if (selectedEmpresaIdSession) {
            setSelectedEmpresaId(selectedEmpresaIdSession);
        }

        listaEmpresas()
            .then((response => {
                const empresasData = response?.data.data;
                setEmpresas(empresasData);

                if (!selectedEmpresaIdSession) {
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }
            }));
    }, [getEmpresaIdSession, setEmpresaIdSession]);

    return (<>
        <Helmet title="Setores" defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to="/">
                        <HomeIcon fontSize="small" />
                    </LinkNavegacao>
                    <Typography variant='span'>Gerenciamento</Typography>
                    <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
                    <Typography variant='span'>Setores</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<PuzzleIcon fontSize='large' />}
                    title='Setores'
                    description='Administre os setores de sua(s) empresa(s).'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <SelectEmpresaFilter
                        empresas={empresas}
                        id_empresas={empresas}
                        defaultValue={selectedEmpresaId}
                        onChangeValue={(value) => {
                            setSelectedEmpresaId(value);
                            setEmpresaIdSession(value);
                            setPage(0);
                        }}
                    />

                    {hasPermission(["admin", "admin_setores", "create_setores"]) &&
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar novo setor
                            </Button>
                        </Typography>
                    }
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <CustomToolbarExportTable
                        rows={setores}
                        columns={columns}
                        reportName="relatorio_setores"
                        reportPdfTitle="RELATÓRIO DE SETORES"
                        getDataWithoutPaginate={() => listaSetores({ id_empresas: [selectedEmpresaId] })}
                        getSelectedEmpresa={() => empresas.find(empresa => empresa.id === Number(selectedEmpresaId))}
                    />

                    <Table aria-label="Setores">
                        <TableHead>
                            <TableRow>
                                <TableCell>Setor</TableCell>
                                <TableCell>Cód. Externo</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : setores && setores?.length > 0
                                    ? setores.map((item) =>
                                        <SectorRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditSector}
                                            handleUpdateStatus={updateSectorStatus}
                                            updateData={carregaSetores}
                                            actionsAllowed={moduleActionsAllowed}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <SectorForm
            open={openForm}
            setOpen={setOpenForm}
            selectedSector={selectedSector}
            empresas={getEmpresasFilteredByModule('Setor', empresas, !selectedSector)}
            actionsAllowed={moduleActionsAllowed}
        />
    </>);
}

export default Sectors;