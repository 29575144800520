import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  Switch,
  IconButton
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { EditIcon, ForkLiftIcon, TrashIcon } from '../../../common/Icons';
import { atualizaPontoEntrega, carregaPontosEntrega, deletaPontoEntrega } from './configs/functions';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { PontoEntregaForm } from './components/PontoEntregaForm';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable';

const PontoDeEntregaRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
  const [active, setActive] = useState(data.status);
  const [openDialog, setOpenDialog] = useState(false);

  async function handleToggleStatus() {
    if (!hasPermission(["admin", "admin_pontoEntrega", "update_pontoEntrega"])) {
      handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
      return false;
    }

    try {
      const res = await atualizaPontoEntrega(data.id, { status: !active })
      if (res) {
        const message = active ? 'Ponto de Entrega inativado com sucesso' : 'Ponto de Entrega ativado com sucesso'
        handleExibirAlerta('Sucesso', message, 'success')
        setActive(!active)
        handleUpdateStatus(data.id, !data.status)
      }
    } catch (error) {
      handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
      console.log(error)
    }
  }

  const handleDeletePontoEntrega = () => {
    const id = data.id;
    deletaPontoEntrega(id)
      .then((response) => {
        const message = response.data.message ?? 'Ponto de Entrega inativado com sucesso';
        handleExibirAlerta('Sucesso', message, 'success');
        handleUpdateStatus(id, false);
        setActive(false);
        updateData();
      }).catch((error) => {
        const message = error.response.data.message ?? 'Erro ao executar operação'
        handleExibirAlerta('Erro', message, 'error');
      })

    setOpenDialog(false)
  }

  return (
    <>
      <TableRow>
        <TableCell>{data.id}</TableCell>
        <TableCell>{data.nome}</TableCell>
        <TableCell>{data.empresa.nome}</TableCell>
        <TableCell>{data.descricao}</TableCell>

        <TableCell>
          <Switch checked={!!active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_pontoEntrega", "update_pontoEntrega"])} />
        </TableCell>

        <TableCell align='right' sx={{ minWidth: 110 }}>
          {hasPermission(["admin", "admin_pontoEntrega", "update_pontoEntrega"]) &&
            <IconButton
              aria-label="Editar"
              onClick={() => handleOpenEditForm(data)}
            >
              <EditIcon />
            </IconButton>}

          {hasPermission(["admin", "admin_pontoEntrega", "delete_pontoEntrega"]) &&
            <IconButton
              aria-label="Excluir"
              onClick={() => setOpenDialog(true)}
            >
              <TrashIcon />
            </IconButton>}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        description={'Tem certeza que deseja excluir esta ponto de entrega?'}
        title={'Excluir Ponto de Entrega'}
        goAction={handleDeletePontoEntrega}
        handleClose={setOpenDialog}
        state={openDialog}
      />
    </>
  )
}

export function ListOfPontosEntrega() {
  const { exibirAlerta } = useCommonItems();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [pontosEntrega, setPontosEntrega] = useState([]);
  const [pontoEntrega, setPontoEntrega] = useState(false);
  const [openForm, setOpenForm] = useState(false)
  const [empresas, setEmpresas] = useState([]);

  const columns = useMemo(() => [
    { field: 'nome', headerName: 'Ponto de Entrega' },
    { field: 'descricao', headerName: 'Descrição' },
    {
      field: 'geolocation',
      headerName: 'Geolocalização',
      valueGetter: (params) => {
        try {
          const geolocation = params.row.geolocation ? JSON.parse(params.row.geolocation) : null;
          if (geolocation?.latitude && geolocation?.longitude) {
            return `${geolocation.latitude}, ${geolocation.longitude}`;
          }
          return 'Não informado';
        } catch (error) {
          console.error('Erro ao processar geolocalização:', error);
          return 'Não informado';
        }
      }
    },
    { field: 'empresa.nome', headerName: 'Empresa', valueGetter: (params) => params.row.empresa?.nome },
    { field: 'status', headerName: 'Status', valueGetter: (params) => (params.row.status === 1 ? 'Ativo' : 'Inativo') },
  ], []);

  function loadPontosEntrega() {
    const timer = setTimeout(() => setIsLoading(true), 500);

    const paginationFilter = {
      limit: rowsPerPage,
      offset: page * rowsPerPage
    };

    carregaPontosEntrega(paginationFilter)
      .then(response => {
        setNumTotalItems(response.data.numero_total);
        setPontosEntrega(response.data.data);
      })
      .catch(error => {
        exibirAlerta('Erro ao carregar os Pontos de Entrega', '', 'error');
      })
      .finally(() => {
        clearTimeout(timer);
        setIsLoading(false);
      });
  }

  function handleEdit(item) {
    setPontoEntrega(item);
    setOpenForm(true);
  }

  useEffect(() => {
    loadPontosEntrega();

    if (!openForm) {
      setPontoEntrega(null);

      listaEmpresas()
        .then((response => {
          setEmpresas(response?.data.data);
        }));
    }
  }, [openForm, page, rowsPerPage]);

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_pontoEntrega", "list_pontoEntrega"];
    checkPermissionsAndRedirect(requiredPermissionsView);
  }, []);

  return (
    <>
      <Helmet title="Pontos de Entrega" defer={false} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="end"
        spacing={3}
      >
        <Grid item xs={12} md={8}>
          <Breadcrumbs>
            <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento</Typography>
            <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
            <Typography variant='span'>Gestão de Pontos de Entrega</Typography>
          </Breadcrumbs>
          <PageTitle icon={<ForkLiftIcon fontSize='large' />} title='Pontos de Entrega' description='Administre os pontos de Entrega das empresas.' />
        </Grid>

        {hasPermission(["admin", "admin_pontoEntrega", "create_pontoEntrega"]) &&
          <Grid item xs={12} md={4}>
            <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => setOpenForm(true)}
              >
                Cadastrar Ponto de Entrega
              </Button>
            </Typography>
          </Grid>
        }

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <CustomToolbarExportTable
              rows={pontosEntrega}
              columns={columns}
              reportName="relatorio_pontos_entrega"
              reportPdfTitle="RELATÓRIO DE PONTOS DE ENTREGA"
              getDataWithoutPaginate={() => carregaPontosEntrega()}
              getSelectedEmpresa={() => null}
            />

            <Table aria-label="Ponto de Entrega">
              <TableHead>
                <TableRow>
                  <TableCell align='center'>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {pontosEntrega?.length > 0
                  ? pontosEntrega.map((item) =>
                    <PontoDeEntregaRow
                      key={item.id}
                      data={item}
                      handleExibirAlerta={exibirAlerta}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleUpdateStatus={() => { }}
                      updateData={loadPontosEntrega}
                    />
                  )
                  : isLoading ? <LoadingTableRow /> : <EmptyTableRow />
                }
              </TableBody>
            </Table>

            <CustomTablePagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <PontoEntregaForm open={openForm} setOpen={setOpenForm} pontoEntrega={pontoEntrega} empresas={empresas} />
    </>
  );
}