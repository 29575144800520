import axios from "axios";
import { urlEmpresas, urlModuloEmpresa, urlModulos } from "../../../../../constants/endpoints";

export const generateIntegrationTokenEmpresa = (id) => axios.post(`${urlEmpresas}/gerarKey/${id}`)

// Modulo Empresa
export const carregaModuloEmpresas = (filter) => axios.get(urlModuloEmpresa, { params: filter })

export const cadastraModuloEmpresa = (data) => axios.post(`${urlModuloEmpresa}`, data) // designar módulos para as empresas

export const atualizaModuloEmpresa = (data) => axios.patch(`${urlModuloEmpresa}/${data.id_modulo_empresa}`, data);

export const manipulaModuloEmpresa = (data, isEditAction, isDeleteAction = false) =>
  isDeleteAction ? deletaModuloEmpresa(data.id_modulo_empresa)
    : isEditAction ? atualizaModuloEmpresa(data)
      : cadastraModuloEmpresa(data)

export const deletaModuloEmpresa = (id) => axios.delete(`${urlModuloEmpresa}/${id}`)