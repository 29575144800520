import { Breadcrumbs, Grid, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PageTitle from '../../../common/PageTitle';
import { SettingsIcon } from '../../../common/Icons';
import LinkNavegacao from '../../../common/Link';
import CardOption from '../../../common/CardOption';

function Settings() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <Typography variant='span'>Gerenciamento</Typography>
                    <Typography variant='span'>Configurações</Typography>
                </Breadcrumbs>
                <PageTitle icon={<SettingsIcon fontSize='large' />} title={'Configurações'} />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h6' mt={1}>
                    Administre as configurações da empresa.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Integrações"
                    description="Configure a integração dos seus dados com sistemas externos."
                    url='integracao'
                />
            </Grid>
        </Grid>
    </>);
}

export default Settings;