import axios from "axios";
import { urlFuncoes, urlPessoas } from "../../../../../constants/endpoints";
import { verificarLimitesEmpresa } from '../../companies/configs/functions';

const listaUsuarios = (userType = null, offset = null, limit = null) => {
    const data = {
        "tipo_usuario": userType,
        limit,
        offset
    };

    return axios.get(urlPessoas, { params: data })
};

const listaUsuariosFilter = (filter = null) => {
    return axios.get(urlPessoas, { params: filter })
};

const getPessoa = (pessoaId) => {
    if (pessoaId) {
        return axios.get(`${urlPessoas}/${pessoaId}`)
    }
}

const atualizaUsuario = (id, data) => axios.patch(`${urlPessoas}/${id}`, data)

const resetAcessoUsuario = (userId) => {
    return axios.patch(`${urlPessoas}/${userId}`, { primeiro_acesso: 1, bio_face_identifier: null, senha: 'password' })
}

const changeUserTypeToCollaborator = (userId) => {
    return axios.patch(`${urlPessoas}/${userId}`, { resetAdmin: true })
}

const deletaUsuario = (id) => {
    if (id) {
        return axios.delete(`${urlPessoas}/${id}`)
    }
}

const cadastrarAbilitiesPessoa = (id, abilities) => {
    if (!id || abilities.length === 0) return Promise.reject('id ou abilities não informados');

    return axios.post(`${urlPessoas}/setPermissaoPessoa`, { id_pessoa: id, abilities })
}

const checkMaxPessoas = async (selectedEmpresaId, empresas, userType) => {
    try {
        const response = await verificarLimitesEmpresa();

        const empresasMarked = markEmpresasWithLimitStatus(response.data, empresas, userType);
        const selectedEmpresa = empresasMarked.find((empresa) => empresa.id === Number(selectedEmpresaId));

        return selectedEmpresa ? selectedEmpresa.limiteAtingido : false;
    } catch (error) {
        console.error('Erro ao carregar contrato:', error);
        return false;
    }
}

// setar a biometria para o usuário
const setBioFace = (id_user, bio_face_identifier) => {
    if (!id_user || !bio_face_identifier) return Promise.reject('Usuário ou foto não informados');

    return axios.post(`${urlPessoas}/setBioFace`, { id_user, bio_face_identifier })
}

// validar a biometria para um usuário
const validarFacial = (id_user, bio_face_identifier) => {
    if (!id_user || !bio_face_identifier) return Promise.reject('Usuário ou foto não informados');

    return axios.post(`${urlPessoas}/validarFacial/${id_user}`, { bio_face_identifier })
}

// altera senha temporária para senha permanente do usuário
const alterarSenhaTemporaria = (data) => axios.post(`${urlPessoas}/alterarSenhaTemporaria`, data)

// Funções
const listaFuncoes = (filter = null) => axios.get(urlFuncoes, { params: filter })
const atualizaFuncao = (id, data) => axios.patch(`${urlFuncoes}/${id}`, data)
const cadastraFuncao = (data) => axios.post(`${urlFuncoes}`, data)
const manipulaFuncao = (data, isEditAction) => isEditAction ? atualizaFuncao(data.id, data) : cadastraFuncao(data)
const deletaFuncao = (id) => axios.delete(`${urlFuncoes}/${id}`);

// utils
function markEmpresasWithLimitStatus(limitesContratos, empresas, tipoPessoa) {
    const getInfo = tipoPessoa === 'administrador' ? 'is_max_colaborador_admin' : 'is_max_colaboradores';

    const limitesMap = new Map(limitesContratos.map((limit) => [limit.id_contrato, limit]));

    return empresas.map((empresa) => {
        const empresaLimit = limitesMap.get(empresa.idContrato);
        const limiteAtingido = empresaLimit ? empresaLimit[getInfo] !== 0 : false;

        return {
            ...empresa,
            limiteAtingido,
        };
    });
}

export {
    listaUsuarios,
    listaUsuariosFilter,
    getPessoa,
    atualizaUsuario,
    resetAcessoUsuario,
    changeUserTypeToCollaborator,
    deletaUsuario,
    cadastrarAbilitiesPessoa,
    checkMaxPessoas,
    setBioFace,
    validarFacial,
    alterarSenhaTemporaria,
    listaFuncoes,
    cadastraFuncao,
    atualizaFuncao,
    manipulaFuncao,
    deletaFuncao,
    markEmpresasWithLimitStatus,
}
