import axios from "axios";
import { urlHabilidades, urlModulos, urlRelatoriosExcelImport, urlRoles } from '../../../../constants/endpoints';

// abilities
export const listaHabilidades = (filter = null) => axios.get(urlHabilidades, { params: filter });
export const cadastraHabilidade = (data) => axios.post(`${urlHabilidades}`, data);
export const atualizaHabilidade = (id, data) => axios.patch(`${urlHabilidades}/${id}`, data);
export const manipulaHabilidade = (data, isEditAction) => isEditAction ? atualizaHabilidade(data.id, data) : cadastraHabilidade(data);
export const deletaHabilidade = (id) => axios.delete(`${urlHabilidades}/${id}`);

// modulos
export const listaModulos = (filter = null) => axios.get(urlModulos, { params: filter });
export const cadastraModulo = (data) => axios.post(`${urlModulos}`, data);
export const atualizaModulo = (id, data) => axios.patch(`${urlModulos}/${id}`, data);
export const manipulaModulo = (data, isEditAction) => isEditAction ? atualizaModulo(data.id, data) : cadastraModulo(data);
export const deletaModulo = (id) => axios.delete(`${urlModulos}/${id}`);

// roles
export const listaRoles = (filter = null) => axios.get(urlRoles, { params: filter });
export const deletaRole = (id) => axios.delete(`${urlRoles}/${id}`);

// planilhas de importação
export const RelatoriosExcelImport = (formData) => axios.post(urlRelatoriosExcelImport, formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});