import { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Grid,
  FormControl,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  DialogActions,
  Button,
} from '@mui/material';
import { fillInputErrors } from '../../../../../configs/functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../../common/CommonDialogActionsForm';
import { carregaModuloEmpresas, manipulaModuloEmpresa } from '../configs/functions';
import { EmptyTableRow } from '../../../../common/EmptyTableRow';
import { IntegrationSkeletonTableRow } from './IntegrationSkeletonTableRow';

function IntegrationRow({ modulo, queryModuloEmpresaData, control, clearErrors, setValue, isAdminSempher }) {
  return (
    <TableRow key={modulo.id}>
      <TableCell>
        <Typography variant="h6">
          {modulo.titulo}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
        >
          {modulo.descricao}
        </Typography>
      </TableCell>

      {modosIntegracao.map((modo) => (
        <TableCell key={modo.value} align='center'>
          <Controller
            name={`modulos[${modulo.id}][${modo.value}]`}
            control={control}
            disabled={!isAdminSempher}
            defaultValue={
              queryModuloEmpresaData?.data?.data.find(
                (item) => item.modulo.id === modulo.id
                  && item.modoIntegracao === modo.value
                  && item.status === 1
              )
              || false
            }
            render={({ field: { onChange: onChangeField, value, ...field } }) => (
              <Checkbox
                {...field}
                checked={value}
                onChange={(e) => {
                  const checked = e.target.checked;
                  onChangeField(e.target.checked);
                  if (checked) {
                    clearErrors(`modulos[${modulo.id}][${modo.value}]`);
                    // desmarca o checkbox do outro modo
                    const otherMode = modo.value === 'ambos' ? 'recebe' : 'ambos';
                    setValue(`modulos[${modulo.id}][${otherMode}]`, false);
                  }
                }}
              />
            )}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

const modosIntegracao = [
  { value: 'ambos', label: 'Enviar e Receber' },
  { value: 'recebe', label: 'Apenas Receber' },
];

export function IntegrationForm({ open, setOpen, selectedCompany, modulos, queryKey, isAdminSempher }) {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();

  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const queryFilter = { id_empresas: [selectedCompany?.id] };
  const { data: queryModuloEmpresaData, isLoading, isFetching } = useQuery({
    queryKey: ['listaModuloEmpresa', queryFilter],
    queryFn: () => carregaModuloEmpresas(queryFilter),
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: manipulaModuloEmpresaFn } = useMutation({
    mutationFn: (data) => manipulaModuloEmpresa(data, data.isEditAction, data.isDeleteAction),
  });

  async function handleFormSubmit(data) {
    try {
      const modulosEmpresa = modulos.flatMap((modulo) => {
        const modosAtivos = modosIntegracao.filter((modo) => data.modulos?.[modulo.id]?.[modo.value]);

        const existentModule = queryModuloEmpresaData?.data?.data.find(
          (item) => item.modulo.id === modulo.id
        );

        // Caso não tenha nenhum modo ativo e exista o módulo, marcar para deletar
        if (modosAtivos.length === 0 && existentModule) {
          return {
            id_modulo_empresa: existentModule.id,
            isDeleteAction: true,
          };
        }

        return modosAtivos.map((modo) => {
          if (existentModule) {
            return {
              id_modulo_empresa: existentModule?.id || null,
              modo_integracao: modo.value,
              status: 1,
              isEditAction: !!existentModule,
            }
          } else {
            return {
              id_modulo_empresa: existentModule?.id || null,
              id_empresa: selectedCompany.id,
              id_modulo: modulo.id,
              modo_integracao: modo.value,
              status: 1,
              isEditAction: !!existentModule,
            }
          }
        });
      }).filter(Boolean).flat();

      if (modulosEmpresa.length === 0) {
        exibirAlerta('Atenção', 'Nenhum módulo ativo para enviar.', 'warning');
        return;
      }

      const results = await Promise.all(
        modulosEmpresa.map(async (moduloData) => {
          if (moduloData.isDeleteAction) {
            return await manipulaModuloEmpresaFn({ id_modulo_empresa: moduloData.id_modulo_empresa, isDeleteAction: true });
          } else {
            return await manipulaModuloEmpresaFn(moduloData);
          }
        })
      );

      if (results.some(result => !result)) {
        throw new Error('Um ou mais módulos não foram processados corretamente.');
      }

      queryClient.invalidateQueries({ queryKey });

      exibirAlerta('Sucesso', 'Módulos processados com sucesso.', 'success');
      setOpen(false);
    } catch (error) {
      console.log(error);
      let errorMessage = "Erro ao processar os módulos.";
      if (error.response) {
        errorMessage = error.response.data.message;
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    }
  }

  useEffect(() => {
    if (open) {
      const defaultValues = {
        modulos: {},
      };

      reset(defaultValues);
    } else {
      reset();
    }
  }, [selectedCompany, open, reset]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>

        <DialogTitle>
          {isAdminSempher ? 'Configurar' : 'Visualizar'} Módulos de Integrações da empresa:
          <br />
          <strong>{selectedCompany?.nomeFantasia}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isAdminSempher
              ? 'Selecione os módulos que a empresa poderá integrar com o sistema e qual a forma de integração.'
              : 'Módulos de integração disponíveis para a empresa.'
            }
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Módulo</TableCell>
                        {modosIntegracao.map((modo) => (
                          <TableCell key={modo.value} align='center'>{modo.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {isLoading || isFetching ? (
                        <IntegrationSkeletonTableRow numRows={5} />
                      ) : modulos.length > 0 ? (
                        modulos.map((modulo) => (
                          <IntegrationRow
                            key={modulo.id}
                            modulo={modulo}
                            queryModuloEmpresaData={queryModuloEmpresaData}
                            control={control}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            isAdminSempher={isAdminSempher}
                          />
                        ))
                      ) : (
                        <EmptyTableRow />
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        {isAdminSempher ? (
          <CommonDialogActionsForm
            errors={errors}
            isLoading={isSubmitting}
            setOpen={setOpen}
          />
        ) : (
          <DialogActions>
            <Button variant='contained' onClick={() => setOpen(false)}>
              Fechar
            </Button>
          </DialogActions>
        )
        }
      </form>
    </Dialog>
  );
}