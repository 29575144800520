import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { useCommonItems } from '../../contexts/CommonItensProvider';
import { validarSenha } from '../screens/management/companies/configs/functions';
import { fillInputErrors, getFormErrorMessage } from '../../configs/functions';

export function PasswordConfirmationDialog({ open, onSuccess, handleClose }) {
  const { exibirAlerta } = useCommonItems();

  const [passwordHidden, setPasswordHidden] = useState(true);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm(
    {
      defaultValues: {
        password: '',
      }
    }
  );

  const { mutateAsync: validarSenhaFn, isPending } = useMutation({
    mutationFn: (senha) => validarSenha(senha),
    onError: (error) => {
      console.log('error:', error);
      exibirAlerta('Erro', error.message, 'error');
    }
  });

  async function handleFormSubmit(data) {
    try {
      const response = await validarSenhaFn(data.senha);

      if (!response) {
        throw new Error('Erro ao validar senha');
      }

      exibirAlerta('Sucesso', 'Senha validada com sucesso.', 'success');
      onSuccess();
    } catch (error) {
      console.log(error);
      let errorMessage = "Ocorreu um erro ao validar a senha.";
      if (error.response) {
        errorMessage = error.response.data.message
        fillInputErrors(error, setError);
      }

      exibirAlerta('Ops', errorMessage, 'error');
    }
  }

  useEffect(() => {
    if (open) {
      reset({ password: '' });
    }
  }, [open, reset]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
      disableEscapeKeyDown
    >
      <DialogTitle>
        Confirmação de Senha
      </DialogTitle>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent dividers>
          <DialogContentText pb={3}>
            <Typography variant="body1" gutterBottom>
              Para continuar, por favor, confirme sua senha.
            </Typography>

          </DialogContentText>

          <Stack
            direction="column"
            width={'100%'}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <TextField
              autoFocus
              fullWidth
              label="Senha"
              type={passwordHidden ? 'password' : 'text'}
              autoComplete='off'
              {...register("senha", { required: true })}
              error={!!errors.senha}
              helperText={getFormErrorMessage(errors, 'senha')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      arrow
                      placement="top"
                      title={passwordHidden ? 'Mostrar Senha' : 'Ocultar Senha'}
                    >
                      <IconButton
                        edge="end"
                        onClick={() => setPasswordHidden((prev) => !prev)}
                      >
                        {passwordHidden
                          ? <Visibility color='primary' />
                          : <VisibilityOff color='primary' />
                        }
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              inputProps={{ form: { autoComplete: 'off' } }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Cancelar
          </Button>

          <Button
            type='submit'
            variant='contained'
            disabled={isPending}
            startIcon={isPending && <CircularProgress size={16} sx={{ color: 'textSecondary' }} />}
          >
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
