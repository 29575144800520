import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { manipulaRegimeTrabalho } from './configs/functions';
import { getDefaultValueSelectField } from '../../../../utils';

export function WorkRegimeForm({ open, setOpen, workRegime, empresas, queryKey }) {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();

  const isEditAction = !!workRegime;

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { dirtyFields, errors, isSubmitting },
  } = useForm();

  const { mutateAsync: manipulaRegimeTrabalhoFn } = useMutation({
    mutationFn: (data) => manipulaRegimeTrabalho(data, isEditAction),
  })

  async function handleFormSubmit(data) {
    try {
      let formDataToSend = {};

      if (isEditAction) {
        const dirtyFieldKeys = Object.keys(dirtyFields);

        const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
          if (data.hasOwnProperty(key)) {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        if (Object.keys(dirtyData).length === 0) {
          exibirAlerta('Atenção', 'Nenhum dado foi alterado.', 'warning');
          return;
        }

        formDataToSend = dirtyData;
        formDataToSend.id = workRegime.id;
      } else {
        delete data.id;
        formDataToSend = data;
        formDataToSend.status = 1;
      }

      const response = await manipulaRegimeTrabalhoFn(formDataToSend);
      if (!response) throw new Error('Erro ao cadastrar Regime de Trabalho.');

      // const manipulatedItem = response.data.regime;
      // updateCacheWithResponse(manipulatedItem);

      // invalidate e refetch para atualizar os dados ao inves de atualizar manualmente pois está ordenando alfabeticamente
      queryClient.invalidateQueries({ queryKey });

      const message = response.data.message ?? `Regime de Trabalho ${isEditAction ? 'editado' : 'cadastrado'} com sucesso.`;
      exibirAlerta('Sucesso', message, 'success');
      setOpen(false);
    } catch (error) {
      console.error(error);
      let errorMessage = "Erro ao cadastrar Regime de Trabalho.";
      if (error.response) {
        errorMessage = error.response.data.message
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    }
  }

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValues = {
        id: workRegime ? workRegime.id : '',
        titulo: workRegime ? workRegime.titulo : '',
        descricao: workRegime ? workRegime.descricao : '',
        id_empresa: getDefaultValueSelectField(workRegime, workRegime?.empresa?.id, empresas),
      };

      reset(defaultValues);
    } else {
      reset();
    }
  }, [workRegime, open, reset, empresas]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Regime de Trabalho <strong>{workRegime.titulo}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Regime de Trabalho</DialogTitle>
        }
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Regime de Trabalho.'}
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Título'
                    placeholder='Digite o Título do Regime de Trabalho'
                    fullWidth
                    {...register("titulo", { required: true })}
                    error={!!errors.titulo}
                    helperText={getFormErrorMessage(errors, 'titulo')}
                    variant="outlined"
                    autoComplete='off'
                    inputProps={{ form: { autoComplete: 'off' } }}
                  />
                </Grid>

                {empresas &&
                  <Grid item xs={12} md={6}>
                    <Controller
                      name='id_empresa'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={workRegime ? workRegime.empresa.id : ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Empresa"
                          onChange={(e) => {
                            setValue('id_empresa', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors("id_empresa");
                            }
                          }}
                          error={!!errors.id_empresa}
                          helperText={getFormErrorMessage(errors, 'id_empresa')}
                        >
                          {empresas.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.nomeFantasia}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                }

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Descrição'
                    placeholder='Digite a Descrição'
                    name='descricao'
                    fullWidth
                    {...register("descricao", { required: true })}
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.descricao}
                    helperText={getFormErrorMessage(errors, 'descricao')}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isSubmitting} setOpen={setOpen} />
      </form>
    </Dialog>
  );
}