import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from "react-hook-form";
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { manipulaModulo } from '../configs/functions';

export function ModuleForm({ open, setOpen, module, queryKey }) {
  const queryClient = useQueryClient();
  const { exibirAlerta } = useCommonItems();

  const isEditAction = !!module;

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { dirtyFields, errors, isSubmitting },
  } = useForm();

  const { mutateAsync: manipulaModuloFn } = useMutation({
    mutationFn: (data) => manipulaModulo(data, isEditAction),
  })

  async function handleFormSubmit(data) {
    try {
      let formDataToSend = {};

      if (isEditAction) {
        const dirtyFieldKeys = Object.keys(dirtyFields);

        const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
          if (data.hasOwnProperty(key)) {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        if (Object.keys(dirtyData).length === 0) {
          exibirAlerta('Atenção', 'Nenhum dado foi alterado.', 'warning');
          return;
        }

        formDataToSend = dirtyData;
        formDataToSend.id = module.id;
      } else {
        delete data.id;
        formDataToSend = data;
      }

      const response = await manipulaModuloFn(formDataToSend);
      if (!response) {
        throw new Error('Erro ao cadastrar Módulo.');
      }

      // invalidate e refetch para atualizar os dados
      queryClient.invalidateQueries({ queryKey });

      exibirAlerta('Sucesso', response.data.message, 'success');
      setOpen(false);
    } catch (error) {
      console.log(error);
      let errorMessage = isEditAction ? 'Erro ao editar Módulo.' : 'Erro ao cadastrar Módulo.';
      if (error.response) {
        errorMessage = error.response.data.error || error.response.data.message;
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    }
  }

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValues = {
        id: module ? module.id : '',
        titulo: module ? module.titulo : '',
        descricao: module ? module.descricao : '',
      };

      reset(defaultValues);
    } else {
      reset();
    }
  }, [module, open, reset]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Módulo: <strong>{module.titulo}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Módulo</DialogTitle>
        }
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Módulo.'}
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <TextField
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Título'
                    placeholder='Digite o Título do Módulo'
                    fullWidth
                    {...register("titulo", { required: true })}
                    error={!!errors.titulo}
                    helperText={getFormErrorMessage(errors, 'titulo')}
                    variant="outlined"
                    autoComplete='off'
                    inputProps={{ form: { autoComplete: 'off' } }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Descrição'
                    placeholder='Digite a Descrição'
                    name='descricao'
                    fullWidth
                    {...register("descricao", { required: true })}
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.descricao}
                    helperText={getFormErrorMessage(errors, 'descricao')}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isSubmitting} setOpen={setOpen} />
      </form>
    </Dialog>
  );
}