import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  IconButton,
  Stack,
  Switch,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { EditIcon, ThumbnailsIcon, TrashIcon } from '../../../common/Icons';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { Pagination } from '../../../common/Pagination';
import { atualizaModulo, deletaModulo, listaModulos } from '../configs/functions';
import { ModuleForm } from './ModuleForm';
import { ModulesTableFilter } from './ModulesTableFilter';
import { FiltersButton } from '../../../common/FiltersButton';

const ModuleRow = ({ data, handleOpenEditForm, handleUpdateStatus, handleOpenDeleteDialog }) => {
  return (
    <>
      <TableRow hover>
        <TableCell>{data.titulo}</TableCell>
        <TableCell>{data.descricao}</TableCell>
        <TableCell>
          <Switch
            checked={!!data.status}
            onChange={handleUpdateStatus}
          />
        </TableCell>
        <TableCell align='right' sx={{ minWidth: 110 }}>
          <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
            <EditIcon />
          </IconButton>

          <IconButton aria-label="Excluir" onClick={() => handleOpenDeleteDialog(data)}>
            <TrashIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export function ListOfModules() {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamPage = searchParams.get('page');
  const searchParamPerPage = searchParams.get('perPage');

  // estados para controle de paginacao
  const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
  const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

  const [selectedModule, setSelectedModule] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [deleteOpenDialog, setOpenDeleteDialog] = useState(false);

  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);

  const queryFilters = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  };

  const filterName = searchParams.get('titulo');
  if (filterName) {
    queryFilters.titulo = filterName;
  }

  const queryKey = ['listaModulos', queryFilters];
  const { data: listaModulesData, isPending: listaModulesIsLoading } = useQuery({
    queryKey,
    queryFn: () => listaModulos(queryFilters)
  });

  const modules = listaModulesData?.data?.data ?? [];
  const numTotalItems = listaModulesData?.data?.numero_total ?? 0;

  const handleEdit = (item) => {
    setSelectedModule(item);
    setOpenForm(true);
  };

  const handleOpenDeleteDialog = (item) => {
    setSelectedModule(item);
    setOpenDeleteDialog(true);
  };

  const handleUpdateStatus = useMutation({
    mutationFn: (data) => atualizaModulo(data.id, data.dataToUpdate),
    onSuccess: (response) => {
      const message = response.data?.message ?? 'Status atualizado com sucesso';
      exibirAlerta('Sucesso', message, 'success');
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    }
  });

  const { mutateAsync: handleDelete } = useMutation({
    mutationFn: deletaModulo,
    onSuccess: (response, id) => {
      const message = response.data.message ?? 'Módulo excluído com sucesso';
      exibirAlerta('Sucesso', message, 'success');

      const cached = queryClient.getQueryData(queryKey);
      const updatedData = {
        ...cached,
        data: {
          ...cached.data,
          data: cached.data.data.filter((item) => item.id !== id),
        },
      };
      queryClient.setQueryData(queryKey, updatedData);
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    },
    onSettled: () => {
      setOpenDeleteDialog(false);
      setSelectedModule(null);
    },
  });

  useEffect(() => {
    setSearchParams((state) => {
      state.set('page', String(page + 1));
      state.set('perPage', String(rowsPerPage));

      return state;
    });
  }, [page, rowsPerPage, setSearchParams]);

  useEffect(() => {
    const queryFilters = {
      ...Object.fromEntries(searchParams),
    }

    const tableFilters = { ...queryFilters };
    delete tableFilters.page;
    delete tableFilters.perPage;

    const activeFilters = Object.keys(tableFilters).length;

    setActiveFilters(activeFilters);
    setIsOpenFilters(activeFilters > 0);
  }, [searchParams]);

  return (
    <>
      <Helmet title="Módulos | Admin " defer={false} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
        <Grid item xs={12} md={9}>
          <Breadcrumbs>
            <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento Admin</Typography>
            <LinkNavegacao to="/admin/organizacao">Organização</LinkNavegacao>
            <Typography variant="span">Módulos</Typography>
          </Breadcrumbs>

          <PageTitle
            icon={<ThumbnailsIcon fontSize="large" />}
            title="Módulos"
            description="Gerencie os módulos do sistema."
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
            <FiltersButton
              isOpenFilters={isOpenFilters}
              setIsOpenFilters={setIsOpenFilters}
              activeFilters={activeFilters}
            />

            <Typography sx={{ textAlign: { xs: 'center', md: 'right' }, mb: 1 }}>
              <Button variant="contained" startIcon={<Add />} onClick={() => setOpenForm(true)}>
                Cadastrar Módulo
              </Button>
            </Typography>
          </Stack>
        </Grid>

        <ModulesTableFilter
          isOpenFilters={isOpenFilters}
          isLoadingList={listaModulesIsLoading}
        />

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Módulos">
              <TableHead>
                <TableRow>
                  <TableCell>Módulo</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listaModulesIsLoading ? (
                  <SkeletonTableRow numCells={4} numRows={rowsPerPage < 20 ? rowsPerPage : 5} />
                ) : modules.length > 0 ? (
                  modules.map((item) => (
                    <ModuleRow
                      key={item.id}
                      data={item}
                      handleUpdateStatus={() => {
                        const dataSendUpdate = { id: item.id, dataToUpdate: { status: !item.status } }
                        handleUpdateStatus.mutateAsync(dataSendUpdate)
                      }}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                    />
                  ))
                ) : (
                  <EmptyTableRow />
                )}
              </TableBody>
            </Table>

            <Pagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <ModuleForm
        open={openForm}
        setOpen={(value) => {
          setOpenForm(value);
          if (!value) {
            setSelectedModule(null);
          }
        }}
        allModules={modules}
        module={selectedModule}
        queryKey={queryKey}
      />

      <ConfirmDialog
        description="Tem certeza que deseja excluir essa Módulo?"
        title="Excluir Módulo"
        goAction={() => handleDelete(selectedModule.id)}
        handleClose={() => {
          setOpenDeleteDialog(false);
          setSelectedModule(null);
        }}
        state={deleteOpenDialog}
      />
    </>
  );
}