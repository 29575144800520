import { useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
    Switch,
    Tooltip
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { formatarCNPJ, hasPermission } from '../../../../configs/functions';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { EditIcon, TrashIcon } from '../../../common/Icons';
import { useModuleContext } from '../../../../contexts/ModuleProvider';

function CompanyUnitRow({ filial, actionsAllowed, handleEmpresa, handleOpenModalFilial, setDeletingFilial }) {
    return (
        <TableRow hover sx={{ backgroundColor: '#f1f1f1' }}>
            <TableCell>{formatarCNPJ(filial.cnpj)}</TableCell>
            <TableCell>{filial.nomeFantasia}</TableCell>
            <TableCell>{`${filial.cidade} - ${filial.estado}`}</TableCell>
            <TableCell>
                <Switch
                    checked={filial.status === 'ativa'}
                    onChange={() => handleEmpresa(filial)}
                    disabled={!hasPermission(["admin", "admin_empresas", "update_empresas"]) || !actionsAllowed}
                />
            </TableCell>
            <TableCell sx={{ minWidth: 150 }}>
                {hasPermission(["admin", "admin_empresas", "update_empresas"]) &&
                    <Tooltip title={actionsAllowed ? "Editar Matriz" : "Visualizar Matriz"}>
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenModalFilial(filial)}
                        >
                            {actionsAllowed ? <EditIcon /> : <Visibility color='primary' />}
                        </IconButton>
                    </Tooltip>
                }

                {hasPermission(["admin", "admin_empresas", "delete_empresas"]) && actionsAllowed &&
                    <Tooltip title="Excluir Filial">
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setDeletingFilial({ id: filial.id, nomeFantasia: filial.nomeFantasia })}
                            disabled={filial.status === 'inativa'}
                        >
                            <TrashIcon />
                        </IconButton>
                    </Tooltip>
                }
            </TableCell>
        </TableRow>
    );
}

function CompanyUnits({ filiais, loaded, handleCarregaFiliais, handleOpenModalFilial }) {
    const { exibirAlerta } = useCommonItems();
    const { verifyIntegrationMode } = useModuleContext();

    const [deletingFilial, setDeletingFilial] = useState(null);

    const deleteEmpresa = (id) => {
        axios.delete(`empresas/${id}`)
            .then((response) => {
                const message = response.data.message ? response.data.message : 'Empresa excluída com sucesso'
                exibirAlerta('Empresa excluída', message, 'success')
                handleCarregaFiliais();
            }).catch((error) => {
                console.log(error);
                exibirAlerta('Erro ao alterar status', '', 'error')
            })

        setDeletingFilial(null);
    }

    const handleEmpresa = (filial) => {
        if (!hasPermission(["admin", "admin_empresas", "update_empresas"])) {
            exibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        const novoStatus = (filial.status === 'ativa') ? 'inativa' : 'ativa'

        axios.patch(`empresas/${filial.id}`, { status: novoStatus })
            .then((response) => {
                const message = filial.status === 'ativa' ? 'Filial inativada com sucesso' : 'Filial ativada com sucesso'
                exibirAlerta('Sucesso', message, 'success')
                handleCarregaFiliais();
            }).catch((error) => {
                console.log(error)
                exibirAlerta('Erro', 'Não foi possível executar a ação.', 'error')
            })
    }

    return (
        <>
            {(filiais && loaded)
                ? <Grid item xs={12} sx={{ backgroundColor: "#f0f0f0", p: 2, borderRadius: 1 }}>
                    <Grid item xs={12} md={9}>
                        <Typography variant='h5' mb={2}>Filiais</Typography>
                    </Grid>

                    <TableContainer>
                        <Table aria-label="Filiais">
                            <TableBody>
                                {filiais.map((filial) =>
                                    <CompanyUnitRow
                                        key={filial.id}
                                        filial={filial}
                                        handleEmpresa={handleEmpresa}
                                        handleOpenModalFilial={handleOpenModalFilial}
                                        actionsAllowed={verifyIntegrationMode("Empresa", filial.id)}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {deletingFilial &&
                        <ConfirmDialog
                            description={`Tem certeza que deseja excluir a filial "${deletingFilial.nomeFantasia}"?`}
                            title={'Excluir Empresa'}
                            goAction={() => deleteEmpresa(deletingFilial.id)}
                            handleClose={() => setDeletingFilial(null)}
                            state={deletingFilial}
                        />
                    }
                </Grid >
                : !loaded && <Typography > <LinearProgress /> Verificando Filiais...</Typography >
            }
        </>
    );
}

export default CompanyUnits;