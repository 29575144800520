import React, { useState } from 'react';
import { CheckCircleOutlineTwoTone, Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Button, CircularProgress, Divider, Typography, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { atualizaStatusRetiradaExtra } from '../functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { grey } from '@mui/material/colors';
import { hasPermission } from '../../../../../configs/functions';
import ConfirmDialog from '../../../../common/ConfirmDialog';

export function ApproveRetiradaExtraModal({ open, setOpen, infoRetirada }) {
  const { exibirAlerta } = useCommonItems();

  const [isLoading, setIsLoading] = useState(false);
  const [openDialogConfirmEntrega, setOpenDialogConfirmEntrega] = useState(false);
  const [openDialogAcceptAndConfirmEntrega, setOpenDialogAcceptAndConfirmEntrega] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  async function handleUpdateStatus(status) {
    setIsLoading(true);
    try {
      await atualizaStatusRetiradaExtra(infoRetirada.id, status);

      const messageStatus = {
        retirado: 'entregue',
        aceito: 'aceita',
        negado: 'recusada'
      }[status] || status;

      exibirAlerta('Sucesso', `Retirada ${messageStatus} com sucesso`, 'success');
      setOpenDialogConfirmEntrega(false);

      if (status === 'aceito') {
        setOpenDialogAcceptAndConfirmEntrega(true);
      } else {
        setOpenDialogAcceptAndConfirmEntrega(false);
        handleClose();
      }
    } catch (error) {
      console.log('Erro ao atualizar status:', error);
      const errorMessage = error?.response?.data?.error || 'Erro ao atualizar Retirada Extra';
      exibirAlerta('Ops!', errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (<>
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      {infoRetirada && (
        <>
          <DialogTitle>Solicitação de Retirada</DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <List sx={{ pt: 0, px: 3 }}>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={"Funcionário:"}
                    secondary={infoRetirada?.pessoa?.nome ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary={"Produto:"}
                    secondary={`${infoRetirada?.produto?.nome ?? "Não Informado(a)"} - ${infoRetirada?.produto?.ca ?? "Não Informado(a)"}`}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary={"Justificativa:"}
                    secondary={infoRetirada?.justificativaOcorrencia?.nome ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={"Equipamento:"}
                      secondary={infoRetirada?.equipamento?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={6}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Responsável Entrega:"
                      secondary={infoRetirada?.responsavel?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary="Empresa:"
                    secondary={infoRetirada?.empresa?.nomeFantasia ?? "Não Informado(a)"}
                  />
                </ListItem>

                <Divider sx={{ width: '100%' }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Grupo:"
                      secondary={infoRetirada?.grupo?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Função:"
                      secondary={infoRetirada?.funcao?.nome ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%', display: { sm: 'block', md: 'none' } }} />

                <Grid item xs={12} md={4}>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Ponto de Entrega:"
                      secondary={infoRetirada?.pontoEntrega ?? "Não Informado(a)"}
                    />
                  </ListItem>
                </Grid>

                <Divider sx={{ width: '100%' }} />

                <ListItem disableGutters>
                  <ListItemText
                    primary="Solicitação criada em:"
                    secondary={infoRetirada?.createdAt ?? "Não Informado(a)"}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </List>

          <Grid container spacing={2} sx={{ px: 3, pb: 2 }}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleClose}
                >
                  Voltar
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} spacing={2}>
              {infoRetirada?.status === 'aceito' && hasPermission(["admin", "admin_retiradaExtra"])
                ? (
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setOpenDialogConfirmEntrega(true)}
                      disabled={isLoading}
                      startIcon={isLoading ? <CircularProgress size={16} sx={{ color: grey[400] }} /> : <CheckCircleOutlineTwoTone />}
                    >
                      Marcar como Entregue
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={() => handleUpdateStatus('negado')}
                        disabled={isLoading || infoRetirada?.status !== 'pendente'}
                        startIcon={isLoading ? <CircularProgress size={16} sx={{ color: grey[400] }} /> : <Close />}
                      >
                        Recusar
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={() => handleUpdateStatus('aceito')}
                        disabled={isLoading || infoRetirada?.status !== 'pendente'}
                        startIcon={isLoading ? <CircularProgress size={16} sx={{ color: grey[400] }} /> : <CheckCircleOutlineTwoTone />}
                      >
                        Aceitar
                      </Button>
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
        </>
      )}
    </Dialog>

    <ConfirmDialog
      title="Atenção!"
      description={
        <>
          <Typography gutterBottom>
            Ao marcar como entregue, você <strong>assume a responsabilidade pela entrega.</strong>
          </Typography>

          <Typography>
            Essa ação não poderá ser desfeita.
          </Typography>
        </>
      }
      confirmText="Entendi, marcar como entregue"
      buttonColor="primary"
      goAction={() => handleUpdateStatus('retirado')}
      state={openDialogConfirmEntrega}
      handleClose={() => setOpenDialogConfirmEntrega(false)}
    />

    <Dialog
      open={openDialogAcceptAndConfirmEntrega}
      onClose={() => {
        setOpenDialogAcceptAndConfirmEntrega(false)
        handleClose();
      }}
    >
      <DialogTitle><strong>Esse Produto já foi entregue?</strong></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            Caso o produto já tenha sido entregue, você pode <strong>marcar como entregue.</strong>
          </Typography>

          <Typography>
            Ao confirmar, você <strong>assume a responsabilidade pela entrega.</strong>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleUpdateStatus('retirado')}>
          Sim, marcar como entregue
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setOpenDialogAcceptAndConfirmEntrega(false)
            handleClose()
          }}
        >
          Não, adicionar ao saldo da pessoa
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}
