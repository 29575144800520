import axios from "axios";
import { urlEmpresas, urlPessoas } from "../../../../../constants/endpoints";
import { getContrato } from '../../contract/functions';

export const listaEmpresas = (companyType = null, offset = null, limit = null) => {
  const data = { limit, offset };

  if (companyType) {
    switch (companyType) {
      case 'matriz':
        data.id_tipo = 1;
        break;
      case 'filial':
        data.id_tipo = 2;
        break;
      default:
        break;
    }
  }

  return axios.get(`${urlEmpresas}`, { params: data })
};

export const verificarLimitesEmpresa = () => axios.get(`${urlEmpresas}/verificarLimitesEmpresa`)

export async function listaEmpresasMatriz(filter = {}) {
  try {
    const res = await axios.get('/empresas/matriz', { params: filter });
    return (res.data.data);
  } catch (e) {
    return (e);
  }
}

export async function listaTiposEmpresas() {
  return axios.get('/tipoEmpresa');
}

export function listaFiliais(idMatriz) {
  return axios.get(`/empresas/filiais/${idMatriz}`);
}

export async function listaEmpresasHasStockModule() {
  return await axios.get('/empresas/moduloEstoque');
}

export async function checkMaxEmpresas(numTotalEmpresas) {
  if (!numTotalEmpresas) return;

  try {
    const res = await getContrato();
    const limit = res.data.data[0]["qtd_empresas"];
    return numTotalEmpresas >= limit;
  } catch (error) {
    console.error('Erro ao carregar contrato:', error);
    return false;
  }
}

export const generateIntegrationTokenEmpresa = (id) => axios.post(`${urlEmpresas}/gerarKey/${id}`)

export const validarSenha = (password) => axios.post(`${urlPessoas}/validarSenha`, { senha: password });
