import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Box,
  DialogContentText,
  StepButton,
  useMediaQuery,
} from '@mui/material';
import { atualizaProduto, buscaProdutoCodigo, cadastrarProduto, listaCategorias, listaGruposByCategoria, listaUnidadesDeMedidas } from './../configs/functions';
import { convertToIsoDate, hasPermission } from '../../../../../configs/functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';

import { Step01 } from './Step01';
import { Step02 } from './Step02';
import { Step03 } from './Step03';
import { Step04 } from './Step04';

dayjs.extend(utc);

export function ProductForm({ open, setOpen, selectedProduct, empresas, queryKey, actionsAllowed }) {
  const queryClient = useQueryClient();
  const isEditAction = !!selectedProduct;

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  // O usuário pode interagir com o formulário se:
  // 1. Possuir permissões ('admin', 'admin_*', 'update_*').
  // 2. For uma ação de cadastro (empresas com Módulo Permitido).
  // 3. For uma edição e a flag `actionsAllowed` for verdadeira.
  const allowInteraction =
    hasPermission(['admin', 'admin_produtos', 'update_produtos']) &&
    (!isEditAction || (isEditAction && actionsAllowed));

  const [activeStep, setActiveStep] = useState(0);
  const [exibiAlertaAutoPreenchimento, setExibiAlertaAutoPreenchimento] = useState(false);

  const [categorias, setCategorias] = useState([]);
  const [unidadesDeMedida, setUnidadesDeMedida] = useState([]);
  const [empresasAssociadas, setEmpresasAssociadas] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [GHEs, setGHEs] = useState([]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
    reset,
  } = useForm();

  const carregaCategorias = async (empresasId = null) => {
    try {
      // para cadastro fazer o get com as empresas que possuem o modulo Produto !== recebe
      if (!empresasId) {
        empresasId = empresas.map(empresa => empresa.id);
      }

      const response = await listaCategorias(empresasId);
      const categoriesData = response.data.data;

      setCategorias(categoriesData);
    } catch (error) {
      console.log(error);
    }
  };

  const carregaUnidadesDeMedida = async () => {
    try {
      const response = await listaUnidadesDeMedidas();
      setUnidadesDeMedida(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function loadInitialData(idEmpresa) {
    try {
      exibirDialog('Carregando dados. Aguarde...');
      await carregaCategorias(idEmpresa);
      await carregaUnidadesDeMedida();
    } catch (error) {
      console.log('error:', error);
      exibirAlerta('Ops', 'Houve um erro ao carregar os dados.', 'error');
    } finally {
      handleCloseDialog();
    }
  }

  function filterEmpresasByCategory(category) {
    // Verificar se a categoria foi encontrada e obter os ids das empresas associadas
    if (!category || empresas.length === 0) {
      return;
    }

    const empresasAssociadasIds = category.empresas_id;
    setEmpresasAssociadas(empresas.filter(empresa => empresasAssociadasIds.includes(empresa.id)));
  }

  const onSubmit = (data) => {
    if (data && (activeStep === 3 || (isEditAction && activeStep === 2))) {
      // remover variacoes que nao foram usadas

      for (const key in data.variacoes) {
        const variacao = data.variacoes[key];

        const shouldRemove = variacao.is_new === true && variacao.ativo === false;

        if (shouldRemove) {
          delete data.variacoes[key];
        }
      }

      if (!data.ca) {
        delete data.ca;
        delete data.validade_ca;
      } else if (data.validade_ca) {
        // force convert validade_ca to YYYY-MM-DD
        // solicitação do para remover hora da data e ignorar utc
        data.validade_ca = dayjs(data.validade_ca).format('YYYY-MM-DD');
      }

      let idGrupo = null;
      if (isEditAction) {
        idGrupo = data.id;
        delete data.id;

        // transform vida_util into array
        data.vida_util = [data.vida_util];
      } else {
        // formatação utilizada para POST (cadastro), onde tem manipulação de empresas
        // formata o id_empresas para se tornar uma array com os ids_empresas selecionados
        // so existe array id_empresas no cadastro
        for (const key in data.id_empresas) {
          if (!data.id_empresas[key]) {
            delete data.id_empresas[key];
          }
        }
        data.id_empresas = Object.keys(data.id_empresas).map(Number);
      }

      categorias.forEach(categoria => {
        // Verifica se o nome da categoria corresponde ao texto em data.id_categorias
        if (categoria.nome === data.id_categorias) {
          // Substitui data.id_categorias pela array de categorias_id da categoria correspondente
          data.id_categorias = categoria.categorias_id;
        }
      });

      try {
        exibirDialog('Realizando operação. Aguarde...');

        if (data.productImage instanceof File) {
          // change name of variable productImage to img
          data.img = data.productImage;
        }
        delete data.productImage;

        // convert values to 1 or 0
        data.exibirRelatorio = data.exibirRelatorio ? 1 : 0;
        data.for_machine = data.for_machine ? 1 : 0;

        // convert data.variacoes in array
        const variacoes = [];
        for (const key in data.variacoes) {
          // nao enviar variacao undefined
          if (data.variacoes[key].rotulo) {
            variacoes.push({
              rotulo: data.variacoes[key].rotulo,
              id_variacao: data.variacoes[key].id_variacao,
              ativo: !!data.variacoes[key].ativo,
              cod_externo: data.variacoes[key].cod_externo,
              is_dirty: data.variacoes[key].is_dirty ?? false,
              is_new: data.variacoes[key].is_new ?? true,
            });
          }
        }

        const formData = new FormData();

        variacoes.forEach((item, index) => {
          formData.append(`variacoes[${index}][nome]`, item.rotulo);
          formData.append(`variacoes[${index}][id_variacao]`, item.id_variacao);
          formData.append(`variacoes[${index}][ativo]`, item.ativo);
          formData.append(`variacoes[${index}][cod_externo]`, item.cod_externo);
          if (isEditAction) {
            formData.append(`variacoes[${index}][is_new]`, !!item.is_new);
          }
        });
        delete data.variacoes;

        const gruposSelecionadosCriarPermissao = data.gruposSelecionadosCriarPermissao;
        if (gruposSelecionadosCriarPermissao && gruposSelecionadosCriarPermissao.length > 0) {
          gruposSelecionadosCriarPermissao.forEach((grupo) => {
            formData.append(`gruposSelecionadosCriarPermissao[${grupo.id}][id]`, grupo.id);
            formData.append(`gruposSelecionadosCriarPermissao[${grupo.id}][status]`, grupo.status ? 1 : 0);
          });
          delete data.gruposSelecionadosCriarPermissao;
          delete data.existsGheWithThisCategory;
        }

        // Append other data fields
        for (const key in data) {
          if (Array.isArray(data[key])) {
            data[key].forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          } else {
            formData.append(key, data[key]);
          }
        }

        if (isEditAction) {
          atualizaProduto(idGrupo, formData)
            .then(() => {
              queryClient.invalidateQueries({ queryKey });
              exibirAlerta('Edição de Produto', `${data.nome} editado com sucesso!`, 'success');
              setOpen(false);
            })
            .catch((error) => {
              console.log('error:', error);
              const messageError = error.response.data.message ?? "Houve algum erro ao efetuar a edição";
              exibirAlerta('Edição de Produto', messageError, 'error');
            });
        } else {
          cadastrarProduto(formData)
            .then(() => {
              queryClient.invalidateQueries({ queryKey });
              exibirAlerta('Cadastro de Produto', `${data.nome} cadastrado com sucesso!`, 'success');
              setOpen(false);
            })
            .catch((error) => {
              console.log('error:', error);
              const messageError = error.response.data.message ?? "Houve algum erro ao efetuar o cadastro";
              exibirAlerta('Cadastro de Produto', messageError, 'error');
            });
        }
      } catch (error) {
        console.log('onSubmit ~ error:', error);
        const action = isEditAction ? 'Edição' : 'Cadastro';
        exibirAlerta(`${action} de Produto`, `Ocorreu um erro ao executar ação.`, 'error');
      } finally {
        handleCloseDialog();
      }
    } else {
      exibirAlerta('Cadastro de Produto', 'Houve algum erro ao efetuar o cadastro.', 'error')
    }
  };

  const validaCamposStep01 = async () => {
    const testDate = dayjs(getValues('validade_ca')).toDate() || [];

    // Verifica se a data é válida ou está vazia para remover o erro
    if (!dayjs(testDate).isValid() || testDate === '') {
      clearErrors('validade_ca');
    } else {
      if (Boolean(getValues('ca')) && !dayjs(testDate).isValid()) {
        setError('validade_ca', { required: true, message: 'A data de validade é obrigatória' });
        return false;
      }
    }

    if (isEditAction) {
      const vidaUtil = getValues('vida_util');
      if (!vidaUtil) {
        setError('vida_util', { type: 'required', message: 'A vida útil é obrigatória' });
        return false;
      } else if (vidaUtil < 1) {
        setError('vida_util', { type: 'min', message: 'A vida útil deve ser maior que 0' });
        return false;
      }
    }

    const isValidNome = await trigger('nome');
    const isValidDescricao = await trigger('descricao');
    const isValidIndicacao = await trigger('indicacao');
    const isValidCategoria = await trigger('id_categorias');
    const isValidUnidade = await trigger('id_unidadeMedida');
    const isValidVidaUtil = await trigger('vida_util');

    const state =
      isValidNome &&
      isValidDescricao &&
      isValidIndicacao &&
      isValidCategoria &&
      isValidUnidade &&
      isValidVidaUtil;

    // etapa apenas para cadastro
    if (!isEditAction && state) {
      const nomeCategoria = getValues('id_categorias');
      let idCategorias = [];

      categorias.forEach(categoria => {
        if (categoria.nome === nomeCategoria) {
          idCategorias = categoria.categorias_id;
        }
      });

      const res = await listaGruposByCategoria(idCategorias);
      const grupos = res.data.grupos;
      setGHEs(grupos);

      if (grupos.length > 0) {
        // Foram encontrados GHE's relacionados a essa categoria
        grupos.forEach((grupo) => {
          setValue(`gruposSelecionadosCriarPermissao[${grupo.id}][id]`, grupo.id);
          setValue(`gruposSelecionadosCriarPermissao[${grupo.id}][status]`, false);
        })

        setValue('existsGheWithThisCategory', true);
      }
    }

    return state;
  };

  const validaCamposStep02 = async () => {
    const variacoes = getValues('variacoes');
    const codigosAtivos = [];
    let variacaoAtiva = false;
    let erroCodigo = false;

    for (const chave in variacoes) {
      clearErrors(`variacoes.${chave}.cod_externo`);

      if (variacoes[chave].ativo) {
        variacaoAtiva = true;
        const codigoAtual = variacoes[chave].cod_externo ? variacoes[chave].cod_externo.trim() : '';
        const codigoOriginal = variacoes[chave].cod_externo_og ? variacoes[chave].cod_externo_og.trim() : '';

        if (!codigoAtual || codigoAtual === '') {
          erroCodigo = true;
          setError(`variacoes.${chave}.cod_externo`, {
            type: 'required',
            message: 'É preciso informar o código'
          });
          // verificar se o campo foi alterado e se o código já existe
        } else if (variacoes[chave].is_dirty && codigoAtual !== codigoOriginal) {
          try {
            const res = await buscaProdutoCodigo(codigoAtual);
            const produto = res.data.data;
            if (produto.length > 0) {
              erroCodigo = true;
              setError(`variacoes.${chave}.cod_externo`, {
                type: 'unique',
                message: 'Código já cadastrado.'
              });
            } else {
              if (codigosAtivos.includes(codigoAtual)) {
                erroCodigo = true;
                setError(`variacoes.${chave}.cod_externo`, {
                  type: 'unique',
                  message: 'O código deve ser único.'
                });
              } else {
                codigosAtivos.push(codigoAtual);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }

    if (!variacaoAtiva) {
      exibirAlerta('Preenchimento do formulário', 'É necessário marcar ao menos um tamanho/variação e informar seus respectivos códigos.', 'error');
    }

    return variacaoAtiva && !erroCodigo;
  };

  const validaStep03 = () => {
    const empresasSelecionadas = Object.entries(getValues('id_empresas')).filter(([key, value]) => value);

    if (empresasSelecionadas.length > 0) {
      // Validar se o campo vida_util das empresas selecionadas foi preenchido
      for (const [key] of empresasSelecionadas) {
        const vidaUtil = getValues(`vida_util.${key}`);
        if (!vidaUtil) {
          setError(`vida_util.${key}`, { type: 'required', message: 'A vida útil é obrigatória' });
          // passar o errors para o companies checkbox e exibir o erro no helperText
          exibirAlerta('Preenchimento do formulário', 'É necessário preencher a vida útil do EPI para cada empresa selecionada.', 'error');
          return false;
        } else if (vidaUtil < 1) {
          setError(`vida_util.${key}`, { type: 'min', message: 'A vida útil deve ser maior que 0' });
          return false;
        }
      }
      return true; // Pelo menos uma empresa foi selecionada
    } else {
      exibirAlerta('Preenchimento do formulário', 'É necessário selecionar ao menos uma empresa.', 'error');
      return false; // Nenhuma empresa foi selecionada
    }
  };

  const handleNext = async (event) => {
    event.preventDefault();

    try {
      exibirDialog('Validando campos. Aguarde...');
      switch (activeStep) {
        case 0:
          if (await validaCamposStep01()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          break;
        case 1:
          if (await validaCamposStep02()) {
            if (isEditAction) {
              setActiveStep((prevActiveStep) => prevActiveStep + 2); // Pular "Empresas Relacionadas"
            } else {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
          break;
        case 2:
          if (validaStep03()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('handleNext ~ error:', error);
      exibirAlerta('Ops', 'Houve um erro ao validar os campos.', 'error');
    } finally {
      handleCloseDialog();
    }
  };

  const handleBack = () => {
    if (isEditAction && activeStep === 3) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2); // Voltar da última etapa diretamente para "Tamanhos e Variações"
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (pos) => {
    if (pos < activeStep) {
      setActiveStep(pos)
    }
  }

  const steps = isEditAction
    ? ['Dados do Produto', 'Tamanhos/variações', 'Cadastro de Produtos']
    : ['Dados do Produto', 'Tamanhos/variações', 'Empresas Relacionadas', 'Cadastro de Produtos'];

  useEffect(() => {
    if (open) {
      const defaultValues = {
        id: selectedProduct?.id || '',
        nome: selectedProduct?.nome || '',
        descricao: selectedProduct?.descricao || '',
        ca: selectedProduct?.ca || '',
        validade_ca: selectedProduct && selectedProduct.validadeCa && dayjs.utc(convertToIsoDate(selectedProduct.validadeCa)),
        indicacao: selectedProduct?.indicacao || '',
        id_categorias: selectedProduct?.categoria?.nome || '',
        id_unidadeMedida: selectedProduct?.unidadeMedida?.id || '',
        // id_estoque: selectedProduct?.estoque?.id || '',
        variacoes: selectedProduct?.variacoes || [],
        precaucao: selectedProduct?.precaucao || '',
        observacao: selectedProduct?.observacao || '',
        productImage: selectedProduct?.img || '',
        exibirRelatorio: selectedProduct ? selectedProduct.exibirRelatorio : true,
        for_machine: selectedProduct ? selectedProduct.forMachine : false,
      };

      if (isEditAction) {
        // o campo vida_util é tratado separadamente na edição
        defaultValues.vida_util = selectedProduct?.vida_util || '';

        // como na edição, não acessa o step de empresas relacionadas, 
        // utiliza-se essa prop no step04 para exibição da empresa relacionada ao Produto
        if (selectedProduct?.idEmpresa) {
          defaultValues.id_empresa = selectedProduct.idEmpresa;
        }

        if (selectedProduct?.filhos) {
          selectedProduct.filhos.forEach(filho => {
            defaultValues.variacoes[filho.variacao.id] = {
              rotulo: filho.variacao.tipo,
              id_variacao: filho.variacao.id,
              cod_externo: filho.cod_externo,
              cod_externo_og: filho.cod_externo,
              ativo: !!filho.status,
              is_dirty: false,
              is_new: false,
            };
          });
        }

        const selectedCategory = categorias.find(categoria => categoria.categorias_id[0] === selectedProduct.categoria.id);
        setSelectedCategory(selectedCategory);
      }

      reset(defaultValues);

      loadInitialData(defaultValues.id_empresa);
    } else {
      reset();
      setExibiAlertaAutoPreenchimento(false);
      setActiveStep(0);
      setSelectedCategory(null);
      setEmpresasAssociadas([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, reset, selectedProduct]);

  useEffect(() => {
    filterEmpresasByCategory(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  return (
    <Dialog open={open} fullWidth maxWidth={'lg'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Cadastrar novo Produto</DialogTitle>
        <DialogContentText sx={{ m: 2 }}>
          Preencha corretamente os dados abaixo para cadastrar um novo Produto.
        </DialogContentText>
        <Stepper activeStep={activeStep} sx={{ px: 3 }} orientation={isMobile ? 'vertical' : 'horizontal'}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={() => handleStep(index)}>
                <StepLabel>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <DialogContent sx={{ minHeight: '65vh' }}>
          <Box sx={{ p: 2, backgroundColor: '#fafafa' }} >
            <FormControl variant="standard" fullWidth >
              {activeStep === 0 &&
                <Step01
                  control={control}
                  errors={errors}
                  setError={setError}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  unidadesDeMedida={unidadesDeMedida}
                  categorias={categorias}
                  exibiAlertaAutoPreenchimento={exibiAlertaAutoPreenchimento}
                  setExibiAlertaAutoPreenchimento={setExibiAlertaAutoPreenchimento}
                  clearErrors={clearErrors}
                  selectedProduct={selectedProduct}
                  setSelectedCategory={setSelectedCategory}
                  isEditAction={isEditAction}
                  actionsAllowed={allowInteraction}
                />
              }
              {activeStep === 1 &&
                <Step02
                  getValues={getValues}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  clearErrors={clearErrors}
                  actionsAllowed={allowInteraction}
                />
              }
              {activeStep === 2 &&
                <Step03
                  empresasAssociadas={empresasAssociadas}
                  GHEs={GHEs}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  setError={setError}
                  errors={errors}
                  clearErrors={clearErrors}
                />
              }
              {activeStep === 3 &&
                <Step04
                  empresas={empresas}
                  empresasAssociadas={empresasAssociadas}
                  GHEs={GHEs}
                  setValue={setValue}
                  getValues={getValues}
                  isEditAction={isEditAction}
                  unidadesDeMedida={unidadesDeMedida}
                />
              }
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained">
            Cancelar
          </Button>
          <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
          {activeStep !== 0 && (
            <Button onClick={handleBack} variant="outlined" type="button">
              Voltar
            </Button>
          )}
          {(!isEditAction && activeStep !== steps.length - 1) || (isEditAction && activeStep !== steps.length) ? (
            <Button onClick={handleNext} variant="contained" type="button">
              Próximo
            </Button>
          ) :
            actionsAllowed && (
              <Button type="submit" variant="contained">
                Salvar
              </Button>
            )}
        </DialogActions>
      </form>
    </Dialog>
  );
}