import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Typography,
    Card,
    CardContent,
    Divider,
} from '@mui/material';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { createDevolucao } from './configs/functions';
import { carregaEstoques } from '../equipments/configs/functions';
import { listaJustificativaDevolucao } from '../guidelines/config/functions';

function DisplayInfo({ label, value }) {
    return (
        <Typography variant="body1" gutterBottom>
            {label}: {value ? <strong>{value}</strong> : <em>Não informado</em>}
        </Typography>
    );
}

export function DevolucaoForm({ open, setOpen, selectedItem, selectedEmpresaId }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [stocks, setStocks] = useState([]);
    const [idJustificativaDevolucao, setIdJustificativaDevolucao] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        control,
        formState: { errors },
    } = useForm();

    async function loadStocks() {
        try {
            const filterAll = { id_empresas: Array.isArray(selectedEmpresaId) ? selectedEmpresaId : [selectedEmpresaId] };
            const response = await carregaEstoques(filterAll);
            const allStocks = response.data.data;
            // remover os que tenham classificacao como 'vending_machine'
            const filteredStocks = allStocks.filter(stock => stock.classificacao[0] !== 'vending_machine');
            return filteredStocks;
        } catch (error) {
            const messageError = error.response.data.error ?? 'Erro ao carregar dados'
            exibirAlerta('Erro', messageError, 'error')
        }
    }

    async function carregaListaJustificativaDevolucao() {
        try {
            const filterByEmpresaId = { id_empresas: [selectedEmpresaId] }
            const response = await listaJustificativaDevolucao(filterByEmpresaId);
            setIdJustificativaDevolucao(response?.data.data)
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao carregar justificativas de devolução";
            if (error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            exibirAlerta('Erro', errorMessage, 'error');
        }
    }

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            exibirDialog('Salvando. Aguarde...');
            const response = await createDevolucao(data);
            exibirAlerta('Sucesso', response.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao devolver equipamento";
            if (error.response) {
                errorMessage = error.response.data.error
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    useEffect(() => {
        if (open) {
            setIsLoading(true);
            const loadData = async () => {
                await carregaListaJustificativaDevolucao();
                const arrayStocks = await loadStocks();
                setStocks(arrayStocks);

                const defaultValues = {
                    id_retirada: selectedItem?.id || '',
                    qtd_produto: selectedItem?.quantidadeProduto || '',
                    id_justificativa_devolucao: '',
                    id_estoque: arrayStocks.length === 1 ? arrayStocks[0].estoques_id[0] : '',
                    observacao: '',
                };
                reset(defaultValues);

                setIsLoading(false);
            };
            loadData();
        } else {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, reset, selectedItem]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Devolução de Equipamento EPI</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        Preencha corretamente os dados abaixo para devolver o equipamento.
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Informações da Retirada
                                </Typography>

                                <DisplayInfo label="Código da Retirada" value={selectedItem?.codigo} />
                                <DisplayInfo label="Equipamento" value={selectedItem?.equipamento?.nome} />
                                <DisplayInfo label="Produto" value={selectedItem?.produto?.nome} />
                                <DisplayInfo label="Quantidade de Itens Entregues" value={selectedItem?.quantidadeProduto} />
                                <DisplayInfo label="Colaborador" value={selectedItem?.pessoa?.nome} />
                            </CardContent>
                        </Card>

                        <Divider sx={{ my: 2 }} />

                        <FormControl variant="standard" fullWidth>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Controller
                                        name='id_estoque'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue=""
                                        render={({ field: { ref, onChange, value, ...field } }) => (
                                            <TextField
                                                {...field}
                                                select
                                                value={value || ""}
                                                fullWidth
                                                disabled={stocks.length === 1}
                                                sx={{ mt: 1 }}
                                                label="Estoque onde o item será devolvido"
                                                onChange={(e) => {
                                                    setValue('id_estoque', e.target.value, { shouldDirty: true });
                                                    if (e.target.value) {
                                                        clearErrors('id_estoque');
                                                    }
                                                }}
                                                error={!!errors.id_estoque}
                                                helperText={getFormErrorMessage(errors, 'id_estoque')}
                                            >
                                                {stocks.length > 0 ? (
                                                    [
                                                        ...stocks.filter(option => option.status !== 0).map(option => (
                                                            <MenuItem key={option.estoques_id[0]} value={option.estoques_id[0]}>
                                                                {option.nome} - ({option.classificacao[0].charAt(0).toUpperCase() + option.classificacao[0].slice(1)})
                                                            </MenuItem>
                                                        )),
                                                        ...stocks.filter(option => option.status === 0).map(option => (
                                                            <MenuItem key={option.estoques_id[0]} value={option.estoques_id[0]} disabled>
                                                                {option.nome} - (Inativo)
                                                            </MenuItem>
                                                        ))
                                                    ]
                                                ) : (
                                                    <MenuItem disabled>Nenhum estoque disponível para a empresa selecionada</MenuItem>
                                                )}
                                            </TextField>
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        inputProps={{ min: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Quantidade de itens devolvidos'
                                        placeholder='Quantidade de itens devolvidos'
                                        name='qtd_produto'
                                        fullWidth
                                        {...register("qtd_produto", {
                                            required: true,
                                            validate: {
                                                lessThan: (value) => value <= selectedItem.quantidadeProduto || 'A quantidade devolvida não pode ser maior que a quantidade entregue.',
                                                moreThan: (value) => value > 0 || 'A quantidade devolvida deve ser maior que zero.'
                                            }
                                        })}
                                        error={!!errors.qtd_produto}
                                        helperText={getFormErrorMessage(errors, 'qtd_produto')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name='id_justificativa_devolucao'
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={selectedItem ? selectedItem.id : ""}
                                        render={({ field: { ref, onChange, ...field } }) => (
                                            <TextField
                                                {...field}
                                                select
                                                fullWidth
                                                sx={{ mt: 1 }}
                                                label="Justificativa de Devolução"
                                                disabled={selectedItem && !selectedItem.id}
                                                onChange={(e) => {
                                                    setValue('id_justificativa_devolucao', e.target.value, { shouldDirty: true });
                                                    if (e.target.value) {
                                                        clearErrors('id_justificativa_devolucao');
                                                    }
                                                }}
                                                error={!!errors.id_justificativa_devolucao}
                                                helperText={getFormErrorMessage(errors, 'id_justificativa_devolucao')}
                                            >
                                                {(idJustificativaDevolucao && idJustificativaDevolucao.length > 0) ? (
                                                    [
                                                        ...idJustificativaDevolucao.filter(option => option.status !== 0).map(option => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.nome}
                                                            </MenuItem>
                                                        )),
                                                        ...idJustificativaDevolucao.filter(option => option.status === 0).map(option => (
                                                            <MenuItem key={option.id} value={option.id} disabled>
                                                                {option.nome} - (Inativo)
                                                            </MenuItem>
                                                        ))
                                                    ]
                                                ) : (
                                                    <MenuItem disabled>Nenhum disponível</MenuItem>
                                                )}
                                            </TextField>
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Observação'
                                        placeholder='Observações sobre a devolução'
                                        name='observacao'
                                        fullWidth
                                        {...register("observacao")}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!errors.observacao}
                                        helperText={getFormErrorMessage(errors, 'observacao')}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>
                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}
