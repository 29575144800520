import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  IconButton,
  Stack,
  Switch
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { ClockIcon, EditIcon, TrashIcon } from '../../../common/Icons';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { listaEmpresas } from '../companies/configs/functions';
import { Pagination } from '../../../common/Pagination';
import { listaRegimesTrabalho, deletaRegimeTrabalho, atualizaRegimeTrabalho } from './configs/functions';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { useAppContext } from '../../../../contexts/AppContext';
import { WorkRegimeForm } from './WorkRegimeForm';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable';

const WorkRegimeRow = ({ data, handleOpenEditForm, handleUpdateStatus, handleOpenDeleteDialog }) => {
  return (
    <TableRow>
      <TableCell>{data.titulo}</TableCell>
      <TableCell>{data.descricao}</TableCell>
      <TableCell>
        <Switch
          checked={!!data.status}
          onChange={handleUpdateStatus}
          disabled={!hasPermission(["admin", "admin_regimeTrabalhos", "update_regimeTrabalhos"])}
        />
      </TableCell>
      <TableCell align='right' sx={{ minWidth: 110 }}>
        {hasPermission(["admin", "admin_regimeTrabalhos", "update_regimeTrabalhos"]) && (
          <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
            <EditIcon />
          </IconButton>
        )}
        {hasPermission(["admin", "admin_regimeTrabalhos", "delete_regimeTrabalhos"]) && (
          <IconButton aria-label="Excluir" onClick={() => handleOpenDeleteDialog(data)}>
            <TrashIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export function ListOfWorkRegimes() {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();
  const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const id_empresa = searchParams.get('id_empresa');
  const searchParamPage = searchParams.get('page');
  const searchParamPerPage = searchParams.get('perPage');

  const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
  const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

  const [selectedWorkRegime, setSelectedWorkRegime] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [deleteOpenDialog, setOpenDeleteDialog] = useState(false);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(getEmpresaIdSession());

  const columns = useMemo(() => [
    { field: 'titulo', headerName: 'Regime de Trabalho' },
    { field: 'descricao', headerName: 'Descrição' },
    { field: 'empresa.nome', headerName: 'Empresa', valueGetter: (params) => params.row.empresa?.nome },
    { field: 'status', headerName: 'Status', valueGetter: (params) => params.row.status === 1 ? 'Ativo' : 'Inativo' },
  ], []);

  const { data: empresasData, isLoading: isLoadingEmpresasData, isPending, error: listaEmpresasError } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response.data.data;

      const selectedEmpresaIdSession = getEmpresaIdSession();
      let defaultEmpresaId = null;
      if (selectedEmpresaIdSession) {
        defaultEmpresaId = selectedEmpresaIdSession
      } else if (empresasData.length > 0) {
        defaultEmpresaId = empresasData[0].id;
        setEmpresaIdSession(empresasData[0].id);
      }

      setSelectedEmpresaId(id_empresa ?? defaultEmpresaId);

      setSearchParams((state) => {
        state.set('id_empresa', id_empresa ?? defaultEmpresaId);
        state.set('page', String(page + 1));
        state.set('perPage', String(rowsPerPage));

        return state;
      });

      return response;
    },
    refetchOnWindowFocus: false,
  });

  const empresas = empresasData?.data?.data ?? [];
  const isLoadingEmpresas = isLoadingEmpresasData || isPending;

  const queryFilters = {
    id_empresas: [selectedEmpresaId],
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  };

  const queryKey = ['listaRegimesTrabalho', queryFilters];
  const { data: listaRegimesTrabalhoData, isPending: listaRegimesTrabalhoIsLoading, error: listaRegimesTrabalhoError } = useQuery({
    queryKey,
    queryFn: () => listaRegimesTrabalho(queryFilters),
    enabled: !!selectedEmpresaId,
  });

  const workRegimes = listaRegimesTrabalhoData?.data?.regimes ?? [];
  const numTotalItems = listaRegimesTrabalhoData?.data?.numero_total ?? 0;

  const handleEdit = (item) => {
    setSelectedWorkRegime(item);
    setOpenForm(true);
  };

  const handleOpenDeleteDialog = (item) => {
    setSelectedWorkRegime(item);
    setOpenDeleteDialog(true);
  };

  const { mutateAsync: handleUpdateStatusFn } = useMutation({
    mutationFn: (data) => atualizaRegimeTrabalho(data.id, data.dataToUpdate),
    onSuccess: (response, variables, context) => {
      const newStatus = variables.dataToUpdate.status;

      const message = newStatus ? 'Regime de Trabalho ativado com sucesso.' : 'Regime de Trabalho inativado com sucesso.';
      exibirAlerta('Sucesso', message, 'success');

      const cached = queryClient.getQueryData(queryKey);

      const updatedData = cached.data.regimes.map((item) => {
        if (item.id === variables.id) {
          return {
            ...item,
            status: newStatus,
          };
        }
        return item;
      });

      queryClient.setQueryData(queryKey, {
        ...cached,
        data: {
          ...cached.data,
          regimes: updatedData,
        },
      });
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    }
  });

  const { mutateAsync: handleDelete } = useMutation({
    mutationFn: deletaRegimeTrabalho,
    onSuccess: (response, id) => {
      const message = response.data.message ?? 'Regime de Trabalho excluído com sucesso';
      exibirAlerta('Sucesso', message, 'success');
      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    },
    onSettled: () => {
      setOpenDeleteDialog(false);
      setSelectedWorkRegime(null);
    },
  });

  useEffect(() => {
    const requiredPermissionsView = ['admin', 'admin_regimeTrabalhos', 'list_regimeTrabalhos'];
    checkPermissionsAndRedirect(requiredPermissionsView);
  }, []);

  useEffect(() => {
    if (listaEmpresasError) {
      exibirAlerta('Erro', 'Erro ao carregar empresas', 'error');
    } else if (listaRegimesTrabalhoError) {
      const message = listaRegimesTrabalhoError?.response?.data?.error || 'Erro ao carregar a lista de regimes de trabalho';
      exibirAlerta('Erro', message, 'error');
    }
  }, [listaEmpresasError, listaRegimesTrabalhoError, exibirAlerta]);

  return (
    <>
      <Helmet title="Regimes de Trabalho" defer={false} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to="/">
              <HomeIcon fontSize="small" />
            </LinkNavegacao>
            <Typography variant="span">Gerenciamento</Typography>
            <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
            <Typography variant="span">Regimes de Trabalho</Typography>
          </Breadcrumbs>
          <PageTitle
            icon={<ClockIcon fontSize="large" />}
            title="Regimes de Trabalho"
            description="Administre os regimes de trabalho das empresas."
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="space-between">
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              isLoading={isLoadingEmpresas}
              onChangeValue={(value) => {
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setSearchParams((state) => {
                  state.set('id_empresa', value);
                  state.set('page', '1');
                  state.set('perPage', String(rowsPerPage));

                  return state;
                });
              }}
            />

            {hasPermission(['admin', 'admin_regimeTrabalhos', 'create_regimeTrabalhos']) && (
              <Grid item xs={12} md={4}>
                <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                  <Button variant="contained" startIcon={<Add />} onClick={() => setOpenForm(true)}>
                    Cadastrar Regime de Trabalho
                  </Button>
                </Typography>
              </Grid>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <CustomToolbarExportTable
              rows={workRegimes}
              columns={columns}
              reportName="relatorio_regime_trabalho"
              reportPdfTitle="RELATÓRIO DE REGIME DE TRABALHO"
              getDataWithoutPaginate={() => {
                const { limit, offset, ...data } = queryFilters;
                return listaRegimesTrabalho(data);
              }}
              getSelectedEmpresa={() => empresas.find(empresa => empresa.id === Number(selectedEmpresaId))}
              dataPath="data.regimes"
            />

            <Table aria-label="Regime de Trabalho">
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listaRegimesTrabalhoIsLoading ? (
                  <SkeletonTableRow
                    numCells={4}
                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                  />
                ) : workRegimes.length > 0 ? (
                  workRegimes.map((item) => (
                    <WorkRegimeRow
                      key={item.id}
                      data={item}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleUpdateStatus={() =>
                        handleUpdateStatusFn({ id: item.id, dataToUpdate: { status: !item.status } })
                      }
                      handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                    />
                  ))
                ) : (
                  <EmptyTableRow />
                )}
              </TableBody>
            </Table>

            <Pagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <WorkRegimeForm
        open={openForm}
        setOpen={(value) => {
          setOpenForm(value);
          if (!value) {
            setSelectedWorkRegime(null);
          }
        }}
        workRegime={selectedWorkRegime}
        empresas={empresas}
        queryKey={queryKey}
      />

      <ConfirmDialog
        description="Tem certeza que deseja excluir este Regime de Trabalho?"
        title="Excluir Regime de Trabalho"
        goAction={() => handleDelete(selectedWorkRegime.id)}
        handleClose={() => {
          setOpenDeleteDialog(false);
          setSelectedWorkRegime(null);
        }}
        state={deleteOpenDialog}
      />
    </>
  );
}