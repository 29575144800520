import { useSearchParams } from 'react-router-dom';
import { TablePagination, useMediaQuery } from '@mui/material';

export function Pagination({ numTotalItems, rowsPerPage, page }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  function handlePaginate(pageIndex) {
    setSearchParams((state) => {
      state.set('page', String(pageIndex + 1))

      return state
    })
  }

  function handleRowsPerPageChange(event) {
    handlePaginate(0);
    const rowsPerPage = parseInt(event.target.value, 10);
    setSearchParams((state) => {
      state.set('perPage', String(rowsPerPage))

      return state
    })
  }

  return (
    <TablePagination
      showLastButton
      showFirstButton
      rowsPerPageOptions={[5, 10, 20, 50, 100]}
      count={numTotalItems}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => handlePaginate(newPage)}
      onRowsPerPageChange={(event) => handleRowsPerPageChange(event)}
      labelRowsPerPage={!isSmallScreen ? 'Linhas por página:' : false}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`}
      component='div' // possibilita o alinhamento à direita
      sx={{ overflow: 'visible' }}
      getItemAriaLabel={(type, page, selected) => {
        if (type === 'first') {
          return 'Primeira página';
        }
        if (type === 'last') {
          return 'Última página';
        }
        if (type === 'next') {
          return 'Próxima página';
        }
        if (type === 'previous') {
          return 'Página anterior';
        }
        return `Página ${page + 1} ${selected ? 'selecionada' : ''}`;
      }}
    />
  )
}
