import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  CircularProgress,
} from '@mui/material';

export function ConfirmGenerateIntegrationTokenDialog({ open, selectedCompany, goAction, handleClose, isLoading }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Confirmação de Geração de novas Chaves de Integração
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText pb={2}>
          <Typography variant="body1" gutterBottom>
            Ao gerar novas chaves, os antigos serão <strong>excluídos permanentemente</strong>. Essa ação é irreversível.
          </Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="body1" gutterBottom>
            Deseja continuar e gerar novas chaves para a empresa: <br /> <strong>{selectedCompany?.nomeFantasia}</strong>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Cancelar
        </Button>

        <Button
          variant='contained'
          onClick={goAction}
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={16} sx={{ color: 'textSecondary' }} />}
        >
          Estou ciente. Gerar Chaves
        </Button>
      </DialogActions>
    </Dialog>
  )
}
