import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { DomainDisabled } from '@mui/icons-material';

export function CompanyLogoUpload({ getValues, setValue, actionsAllowed }) {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const storedImage = getValues('logo');
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, [getValues]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setSelectedImage(base64Image);
        setValue('logo', base64Image, { shouldDirty: true });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setValue('logo', null, { shouldDirty: true });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      {selectedImage ? (
        <>
          <Box
            component={'img'}
            src={selectedImage}
            sx={{
              width: 150,
              height: 150,
              borderRadius: '50%',
              objectFit: 'cover'
            }}
          />
          <Button onClick={handleRemoveImage} variant="outlined" disabled={!actionsAllowed}>
            Remover Logo
          </Button>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 150,
              height: 150,
              bgcolor: '#f5f5f5',
              borderRadius: '50%',
              overflow: 'hidden'
            }}
          >
            <DomainDisabled sx={{ fontSize: '60px', color: '#00000033' }} />
          </Box>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id="company-logo-upload"
          />
          <label htmlFor="company-logo-upload">
            <Button variant="outlined" component="span" disabled={!actionsAllowed}>
              Adicionar Logo
            </Button>
          </label>
        </>
      )}
    </Box>
  );
}
